import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { AiOutlineAlignRight } from 'react-icons/ai'
import { Tabs, Input, Button, DatePicker,Select, Table } from 'antd';
import { allAttendanceDetailsGet, classAttendanceDetailsGet,  classMasterGet, subjectAttendanceDetailsGet, employeeMasterGet, subjectMasterGet } from '../../APIs/API';
import axios from 'axios';
import { Option } from 'antd/es/mentions'
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import loaderImg from '../../Assets/loader1.gif'

const AttendanceDetailsReport = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  //========== Check for session storage and redirect if empty======
  useEffect(() => {
    const token = sessionStorage.getItem('token'); 

    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const dateFormatList = ['DD/MM/YYYY'];

  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

// ##############################>>> ALL TABLE CODE START <<<#####################################

    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    

       //========== Fetch Table Data =========
       const handleShowAll = ()=>{
        setIsLoading(true);
        if (!fromDate || !toDate ) {
          toast.error("Please select all fields before saving");
          return;
      }       

         // Format dates to dd/mm/yyyy
         const formattedFromDate = fromDate ? formatDate(new Date(fromDate)) : null;
         const formattedToDate = toDate ? formatDate(new Date(toDate)) : null;         
      
        axios.get(`${allAttendanceDetailsGet}/${formattedFromDate}/${formattedToDate}`)
        .then(response => {        
            setData(response.data); 
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching attendance data:', error);
        });
      }

      const columns = [
        {
          title: 'Sr. No.',
          key: 'index',
          render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: 'Class Name',
          dataIndex: 'classname',
          key: 'classname',
        },
        {
          title: 'Subject Name',
          dataIndex: 'subjectname',
          key: 'subjectname',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: 'Time',
          dataIndex: 'time',
          key: 'time',
        },
        {
          title: 'Roll No',
          dataIndex: 'roll_no',
          key: 'roll_no',
        },
        {
          title: 'Student Name',
          dataIndex: 'studentname',
          key: 'studentname',
        },
        // {
        //   title: 'Teacher Name',
        //   dataIndex: 'teachername',
        //   key: 'teachername',
        // },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        }
        
      ];

      // Filter data based on search input
      const filteredData = data.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
      );

       // Handle Cancel button click
      const handleCancel = () => {  
        setFromDate();
        setToDate();
        setData([]); // Clear the data
      };
      
      // ==================== Export to Excel ====================
      const handleDownloadExcel = () => {
        if (data.length === 0) {
          toast.error("No data available to download");
          return;
        }

        const excelData = data.map((row, index) => ({
          "Class Name": row.classname,
          "Subject Name": row.subjectname,
          "Date": row.date,
          "Time": row.time,
          "RollNo": row.roll_no,
          "Student Name": row.studentname,
          // "Teacher Name": row.teachername,
          "Description": row.description,
        }));
      
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "All Attendance Details Data");

        // Set up the Excel file name
        XLSX.writeFile(workbook, 'All_Attendance_Details_Data.xlsx');
      };

      // ==================== Export to PDF ====================
      const handleDownloadPdf = () => {
        if (data.length === 0) {
          toast.error("No data available to download");
          return;
        }

        const doc = new jsPDF();

        // Get page width and calculate the center position for the college name
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const collegeName = "Latthe Education Society's";
        const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
        const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

        doc.setFontSize(10); 
        const textWidth1 = doc.getTextWidth(collegeName);
        const centerX1 = (pageWidth - textWidth1) / 2; 
        doc.text(collegeName, centerX1, 10); 
      
        doc.setFontSize(17); 
        const textWidth2 = doc.getTextWidth(collegeName1);
        const centerX2 = (pageWidth - textWidth2) / 2;
        doc.text(collegeName1, centerX2, 16); 

        doc.setFontSize(7); 
        const textWidth3 = doc.getTextWidth(collegeName2);
        const centerX3 = (pageWidth - textWidth3) / 2;
        doc.text(collegeName2, centerX3, 20); 
      
        // Set font size for the teacher and date
        doc.setFontSize(10);

        // Add a border function that runs for every page
        const addBorder = () => {
          doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
        };


        // Convert the data to rows for jsPDF autotable
        const tableColumn = ['Class Name', 'Subject Name', 'Date', 'Time','Roll No', 'Student Name', 'Description'];
        const tableRows = data.map((row,index) => [
          row.classname,
          row.subjectname,
          row.date,
          row.time,
          row.roll_no,
          row.studentname,
          // row.teachername,
          row.description,
        ]);

        // Add the table to PDF
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: 25, // Adjust to position the table below the title
          styles: {
            lineWidth: 0.1, // Border thickness for table cells
            lineColor: [0, 0, 0], // Black border color
          },
          tableLineColor: [0, 0, 0], // Border color for table's outer frame
          tableLineWidth: 0.1, // Border thickness for the table's outer frame
            didDrawPage: (data) => {
              // This callback is called for each page created by autoTable
              addBorder(); // Draw the border on every new page

              // Add page number at the bottom of each page
              doc.setFontSize(10);
              doc.text(`Page No. ${data.pageNumber}`, pageWidth - 30, pageHeight - 10);

            },
        });

        // Save the PDF
        doc.save('All_Attendance_Details_Data.pdf');
      };

// $$$$$$$$$$$$$$$$$$$$$$$$$$$>>> ALL TABLE CODE END <<<$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

// ##############################>>> CLASS WISE TABLE CODE START <<<#####################################

const [data1, setData1] = useState([]);
const [classOptions, setClassOptions] = useState([]);
const [selectedClass, setSelectedClass] = useState(null);
const [fromDate1, setFromDate1] = useState(null);
const [toDate1, setToDate1] = useState(null);
const [searchText1, setSearchText1] = useState('');
const [pageSize1, setPageSize1] = useState(10);
const [currentPage1, setCurrentPage1] = useState(1);


        // Fetch Class Options
        const FetchClassOptions = () => {
          axios
            .get(classMasterGet) 
            .then((response) => {
              setClassOptions(response.data);
            })
            .catch((error) => {
              console.error('Error fetching class options:', error);
            });
        };

      useEffect(() => {
        FetchClassOptions();
      }, []);

   //========== Fetch Table Data =========
   const handleShowClassWise = ()=>{
    setIsLoading(true);
    if (!fromDate1 || !toDate1 || !selectedClass ) {
      toast.error("Please select all fields before saving");
      return;
  }       

     // Format dates to dd/mm/yyyy
     const formattedFromDate1 = fromDate1 ? formatDate(new Date(fromDate1)) : null;
     const formattedToDate1 = toDate1 ? formatDate(new Date(toDate1)) : null;         

    axios.get(`${classAttendanceDetailsGet}/${selectedClass}/${formattedFromDate1}/${formattedToDate1}`)
    .then(response => {        
        setData1(response.data); 
        setIsLoading(false);
    })
    .catch(error => {
        console.error('Error fetching attendance data:', error);
    });
  }

  const columns1 = [
    {
      title: 'Sr. No.',
      key: 'index',
      render: (text, record, index) => (currentPage1 - 1) * pageSize1 + index + 1,
    },
    {
      title: 'Class Name',
      dataIndex: 'classname',
      key: 'classname',
    },
    {
      title: 'Subject Name',
      dataIndex: 'subjectname',
      key: 'subjectname',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Roll No',
      dataIndex: 'roll_no',
      key: 'roll_no',
    },
    {
      title: 'Student Name',
      dataIndex: 'studentname',
      key: 'studentname',
    },
    // {
    //   title: 'Teacher Name',
    //   dataIndex: 'teachername',
    //   key: 'teachername',
    // },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    
  ];

  // Filter data based on search input
  const filteredData1 = data1.filter(item =>
  Object.values(item).some(value =>
    value.toString().toLowerCase().includes(searchText1.toLowerCase())
  )
  );

   // Handle Cancel button click
  const handleCancel1 = () => {  
    setSelectedClass();
    setFromDate1();
    setToDate1();
    setData1([]); 
  };
  
  // ==================== Export to Excel ====================
  const handleDownloadExcel1 = () => {
    if (data1.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const excelData1 = data1.map((row, index) => ({
      "Class Name": row.classname,
      "Subject Name": row.subjectname,
      "Date": row.date,
      "Time": row.time,
      "Roll No": row.roll_no,
      "Student Name": row.studentname,
      // "Teacher Name": row.teachername,
      "Description": row.description,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(excelData1);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Class Wise Attendance Details Data");

    // Set up the Excel file name
    XLSX.writeFile(workbook, 'Class_Wise_Attendance_Details_Data.xlsx');
  };

  // ==================== Export to PDF ====================
  const handleDownloadPdf1 = () => {
    if (data1.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const doc = new jsPDF();

    // Get page width and calculate the center position for the college name
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

        const collegeName = "Latthe Education Society's";
        const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
        const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

        doc.setFontSize(10); 
        const textWidth1 = doc.getTextWidth(collegeName);
        const centerX1 = (pageWidth - textWidth1) / 2; 
        doc.text(collegeName, centerX1, 10); 
      
        doc.setFontSize(17); 
        const textWidth2 = doc.getTextWidth(collegeName1);
        const centerX2 = (pageWidth - textWidth2) / 2;
        doc.text(collegeName1, centerX2, 16); 

        doc.setFontSize(7); 
        const textWidth3 = doc.getTextWidth(collegeName2);
        const centerX3 = (pageWidth - textWidth3) / 2;
        doc.text(collegeName2, centerX3, 20); 
      
        // Set font size for the teacher and date
        doc.setFontSize(10);

     // Add a border function that runs for every page
    const addBorder = () => {
      doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
    };


    // Convert the data to rows for jsPDF autotable
    const tableColumn = ['Class Name', 'Subject Name', 'Date', 'Time','Roll No','Student Name', 'Description',];
    const tableRows = data1.map((row,index) => [
      row.classname,
      row.subjectname,
      row.date,
      row.time,
      row.roll_no,
      row.studentname,
      // row.teachername,
      row.description,
    ]);

    // Add the table to PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25, // Adjust to position the table below the title
      styles: {
        lineWidth: 0.1, // Border thickness for table cells
        lineColor: [0, 0, 0], // Black border color
      },
      tableLineColor: [0, 0, 0], // Border color for table's outer frame
      tableLineWidth: 0.1, // Border thickness for the table's outer frame
        didDrawPage: (data1) => {
          // This callback is called for each page created by autoTable
          addBorder(); // Draw the border on every new page

        // Add page number at the bottom of each page
        doc.setFontSize(10);
        doc.text(`Page No. ${data1.pageNumber}`, pageWidth - 30, pageHeight - 10);

        },
    });

    // Save the PDF
    doc.save('Class_Wise_Attendance_Details_Data.pdf');
  };

// $$$$$$$$$$$$$$$$$$$$$$$$$$$>>> CLASS WISE TABLE CODE END <<<$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

// ##############################>>> SUBJECT WISE TABLE CODE START <<<#####################################

const [data2, setData2] = useState([]);
const [subjectOptions, setSubjectOptions] = useState([]);
const [selectedClass1, setSelectedClass1] = useState(null);
const [selectedSubject, setSelectedSubject] = useState(null);
const [fromDate2, setFromDate2] = useState(null);
const [toDate2, setToDate2] = useState(null);
const [searchText2, setSearchText2] = useState('');
const [pageSize2, setPageSize2] = useState(10);
const [currentPage2, setCurrentPage2] = useState(1);


     // Fetch Subject Options
  const FetchSubjectOptions = () => {
    axios
      .get(subjectMasterGet)
      .then((response) => {
        setSubjectOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subject options:", error);
      });
  };

      useEffect(() => {
        FetchSubjectOptions();
      }, []);

   //========== Fetch Table Data =========
   const handleShowSubjectWise = ()=>{
    setIsLoading(true);
    if (!fromDate2 || !toDate2 || !selectedClass1 || !selectedSubject ) {
      toast.error("Please select all fields before saving");
      return;
  }       

     // Format dates to dd/mm/yyyy
     const formattedFromDate2 = fromDate2 ? formatDate(new Date(fromDate2)) : null;
     const formattedToDate2 = toDate2 ? formatDate(new Date(toDate2)) : null;    
       

    axios.get(`${subjectAttendanceDetailsGet}/${selectedClass1}/${selectedSubject}/${formattedFromDate2}/${formattedToDate2}`)
    .then(response => {       
        setData2(response.data); 
        setIsLoading(false);
    })
    .catch(error => {
        console.error('Error fetching attendance data:', error);
    });
  }

  // const columns2 = [
  //   {
  //     title: 'Sr. No.',
  //     key: 'index',
  //     render: (text, record, index) => (currentPage2 - 1) * pageSize2 + index + 1,
  //   },
  //   {
  //     title: 'Roll No',
  //     dataIndex: 'roll_no',
  //     key: 'roll_no',
  //   },
  //   {
  //     title: 'Teacher Name',
  //     dataIndex: 'teachername',
  //     key: 'teachername',
  //   },
  //   {
  //     title: 'Student Name',
  //     dataIndex: 'studentname',
  //     key: 'studentname',
  //   },
  //   {
  //       title: 'Class Name',
  //     dataIndex: 'classname',
  //     key: 'classname',
  //   },
  //   {
  //     title: 'Subject Name',
  //     dataIndex: 'subjectname',
  //     key: 'subjectname',
  //   },
  //   {
  //     title: 'Date',
  //     dataIndex: 'date',
  //     key: 'date',
  //   },
  //   {
  //     title: 'Time',
  //     dataIndex: 'time',
  //     key: 'time',
  //   },
  //   {
  //     title: 'Description',
  //     dataIndex: 'description',
  //     key: 'description',
  //   },
    
  // ];

  // Filter data based on search input
  // const filteredData2 = data2.filter(item =>
  // Object.values(item).some(value =>
  //   value.toString().toLowerCase().includes(searchText2.toLowerCase())
  // )
  // );
  

   // Handle Cancel button click
  
   const handleCancel2 = () => {  
    setSelectedSubject();
    setSelectedClass1();
    setFromDate2();
    setToDate2();
    setData2([]); 
  };
  
  // ==================== Export to Excel ====================
  // const handleDownloadExcel2 = () => {
  //   if (data2.length === 0) {
  //     toast.error("No data available to download");
  //     return;
  //   }

  //   const excelData2 = data2.map((row, index) => ({
  //     "Roll No": row.roll_no,
  //     "Teacher Name": row.teachername,
  //     "Student Name": row.studentname,
  //     "Class Name": row.classname,
  //     "Subject Name": row.subjectname,
  //     "Date": row.date,
  //     "Time": row.time,
  //     "Description": row.description,
  //   }));
  
  //   const worksheet = XLSX.utils.json_to_sheet(excelData2);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Subject Wise Attendance Details Data");

  //   // Set up the Excel file name
  //   XLSX.writeFile(workbook, 'Subject_Wise_Attendance_Details_Data.xlsx');
  // };
  const handleDownloadExcel2 = () => {
    if (!data2 || !data2.lectures) {
      toast.error("No data available to download");
      return;
    }
  
    const workbook = XLSX.utils.book_new();
  
    // Add each lecture to the workbook as a separate sheet
    data2.lectures.forEach((lecture, lectureIndex) => {
      if (!lecture.classname || !lecture.subjectname) {
        console.error("Invalid lecture data:", lecture);
        return;
      }
  
      const sheetData = [];
  
      // Add lecture details
      sheetData.push([`Class Name: ${lecture.classname}`, `Subject Name: ${lecture.subjectname}`]);
      sheetData.push([`Lecture Date: ${lecture.lec_date}`, `Lecture Time: ${lecture.lec_time}`]);
      sheetData.push([]); // Empty row for spacing
  
      // Add table headers
      sheetData.push(["Roll No", "Student Name", "Reg No", "Attendance"]);
  
      // Add student data
      lecture.rollNo.forEach(student => {
        sheetData.push([
          student.roll_no || "",
          student.studentname || "",
          student.reg_no || "",
          student.flag === 1 ? "Present" : "Absent"
        ]);
      });
  
      // Create a worksheet and add it to the workbook
      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const sheetName = `Lecture ${lectureIndex + 1}`;
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });
  
    // Generate the Excel file and download it
    XLSX.writeFile(workbook, "Attendance_Details_Report.xlsx");
  };

  // ==================== Export to PDF ====================
  // const handleDownloadPdf2 = () => {
  //   if (data2.length === 0) {
  //     toast.error("No data available to download");
  //     return;
  //   }

  //   const doc = new jsPDF();

  //   // Get page width and calculate the center position for the college name
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();

  //       const collegeName = "Latthe Education Society's";
  //       const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
  //       const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

  //       doc.setFontSize(10); 
  //       const textWidth1 = doc.getTextWidth(collegeName);
  //       const centerX1 = (pageWidth - textWidth1) / 2; 
  //       doc.text(collegeName, centerX1, 10); 
      
  //       doc.setFontSize(17); 
  //       const textWidth2 = doc.getTextWidth(collegeName1);
  //       const centerX2 = (pageWidth - textWidth2) / 2;
  //       doc.text(collegeName1, centerX2, 16); 

  //       doc.setFontSize(7); 
  //       const textWidth3 = doc.getTextWidth(collegeName2);
  //       const centerX3 = (pageWidth - textWidth3) / 2;
  //       doc.text(collegeName2, centerX3, 20); 
      
  //       // Set font size for the teacher and date
  //       doc.setFontSize(10);

  //    // Add a border function that runs for every page
  //  const addBorder = () => {
  //   doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
  // };


  //   // Convert the data to rows for jsPDF autotable
  //   const tableColumn = ['Roll No','Teacher Name','Student Name','Class Name', 'Subject Name', 'Date', 'Time', 'Description',];
  //   const tableRows = data2.map((row,index) => [
  //     row.roll_no,
  //     row.teachername,
  //     row.studentname,
  //     row.classname,
  //     row.subjectname,
  //     row.date,
  //     row.time,
  //     row.description,
  //   ]);

  //   // Add the table to PDF
  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 25, // Adjust to position the table below the title
  //     styles: {
  //       lineWidth: 0.1, // Border thickness for table cells
  //       lineColor: [0, 0, 0], // Black border color
  //     },
  //     tableLineColor: [0, 0, 0], // Border color for table's outer frame
  //     tableLineWidth: 0.1, // Border thickness for the table's outer frame
  //       didDrawPage: (data2) => {
  //         // This callback is called for each page created by autoTable
  //         addBorder(); // Draw the border on every new page

  //         // Add page number at the bottom of each page
  //         doc.setFontSize(10);
  //         doc.text(`Page No. ${data2.pageNumber}`, pageWidth - 30, pageHeight - 10);

  //       },
        
  //   });

  //   // Save the PDF
  //   doc.save('Employee_Wise_Attendance_Details_Data.pdf');
  // };

  const handleDownloadPdf2 = () => {
    if (!data2 || !data2.lectures) {
      toast.error("No data available to download");
      return;
    }
  
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
    const collegeName = "Latthe Education Society's";
    const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
    const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";
    // const fromDate = data2.lectures.lec_date;
    // const toDate = data2.lectures.todate;
  
    // Add college names and header to PDF
    try {
      doc.setFontSize(10);
      const centerX1 = (pageWidth - doc.getTextWidth(collegeName)) / 2;
      doc.text(collegeName, centerX1, 10);
  
      doc.setFontSize(17);
      const centerX2 = (pageWidth - doc.getTextWidth(collegeName1)) / 2;
      doc.text(collegeName1, centerX2, 16);
  
      doc.setFontSize(7);
      const centerX3 = (pageWidth - doc.getTextWidth(collegeName2)) / 2;
      doc.text(collegeName2, centerX3, 20);
  
      // doc.setFontSize(10);
      // const dateRangeText = `From: ${fromDate} - To: ${toDate}`;
      // const xPositionDate = pageWidth - doc.getTextWidth(dateRangeText) - 15;
      // doc.text(dateRangeText, xPositionDate, 27);
    } catch (error) {
      console.error("Error adding header text:", error);
      return;
    }
  
    let startY = 27; 
  
    // Iterate through each lecture
    data2.lectures.forEach((lecture, lectureIndex) => {
      if (!lecture.classname || !lecture.subjectname) {
        console.error("Invalid lecture data:", lecture);
        return;
      }
  
      try {
        let startY = 28; 

        const collegeName = "Latthe Education Society's";
        const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
        const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

        doc.setFontSize(10);
        const centerX1 = (pageWidth - doc.getTextWidth(collegeName)) / 2;
        doc.text(collegeName, centerX1, 10);
    
        doc.setFontSize(17);
        const centerX2 = (pageWidth - doc.getTextWidth(collegeName1)) / 2;
        doc.text(collegeName1, centerX2, 16);
    
        doc.setFontSize(7);
        const centerX3 = (pageWidth - doc.getTextWidth(collegeName2)) / 2;
        doc.text(collegeName2, centerX3, 20);

        // Add lecture details
        doc.setFontSize(10);
        doc.text(`Class Name: ${lecture.classname}`, 15, startY);
        doc.text(`Subject Name: ${lecture.subjectname}`, pageWidth - doc.getTextWidth(`Subject Name: ${lecture.subjectname}`) - 15, startY);
  
        doc.setFontSize(10);
        doc.text(`Lecture Date: ${lecture.lec_date}`, 15, startY + 6);
        doc.text(`Lecture Time: ${lecture.lec_time}`, pageWidth - doc.getTextWidth(`Lecture Time: ${lecture.lec_time}`) - 15, startY + 6);
        // doc.text(`Description: ${lecture.description}`, 15, startY + 12);
  
        const tableColumn = [
          "Roll No", "Student Name", "Reg No", "Attendance"
        ];
        const tableRows = lecture.rollNo.map(student => [
          student.roll_no || "", 
          student.studentname || "", 
          student.reg_no || "", 
          student.flag == 1 ? "Present" : "Absent"
        ]);
  
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: startY + 10,
          styles: {
            lineWidth: 0.1,
            lineColor: [0, 0, 0],
          },
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0.1,
          didDrawPage: (data2) => {
            doc.rect(5, 5, pageWidth - 10, pageHeight - 10);
  
            doc.setFontSize(8);
            doc.text(`Page No. ${data2.pageNumber}`, pageWidth - 30, pageHeight - 10);
          },
        });
  
        startY = doc.autoTable.previous.finalY + 10;
  
        if (lectureIndex < data2.lectures.length - 1) {
          doc.addPage();
          startY = 10;
        }
      } catch (error) {
        console.error("Error processing lecture:", lecture, error);
      }
    });
  
    doc.save("Attendance_Details_Report.pdf");
  };
  


// $$$$$$$$$$$$$$$$$$$$$$$$$$$>>> SUBJECT WISE TABLE CODE END <<<$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  // ======Tabs Code===========================================================================================

  const TabContent1 = () => (
    <div className='row p-0 m-0' style={{border:"1px solid #B3C8CF"}}>

        <div className='row p-0 px-md-5 m-0'>

          <div className='row p-0 px-md-5 m-0 py-3 pb-5'>
                <div className='col-12 col-md-2 pt-2'></div>
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>From Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                            <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={fromDate}  onChange={(date) => setFromDate(date)} />
                        </div>
                    </div>
                </div> 
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>To Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                        <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={toDate}  onChange={(date) => setToDate(date)} />
                        </div>
                    </div> 
                </div> 
                <div className='col-12 col-md-2 pt-4 pt-md-2 d-flex align-items-center justify-content-center'>
                      <Button className='saveBtn' onClick={handleShowAll}>Show Data</Button>
                      <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
                </div>
                <div className='col-12 col-md-2'></div>
          </div>

        </div>

         {/* ======>> table data <<====== */}
         <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

         {data.length > 0 ? (
          <>
          {isLoading ? (
                <div className="text-center">
                  <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                </div>
             ) : (
            <>
              <div className='col-12 py-4 text-end'>
                  <Button className='downloadBtn' onClick={handleDownloadExcel}> Download Excel</Button>
                  <Button className='downloadBtn' onClick={handleDownloadPdf}>Download Pdf</Button>
              </div>
              
              <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                  <div className='col-12 col-lg-6 text-start text-md-start'>
                  <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                          <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                              <option value={10}>10</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                          </select>
                  </div>
                  <div className='col-12 col-lg-6 text-start text-md-end'>
                  <span style={{fontWeight:500}}>Search &nbsp; </span>
                      <Input
                      placeholder="Search Here"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      style={{ width: 200 }}
                      />
                  </div>                   
              </div>

              <Table style={{overflow:"auto"}} columns={columns} 
                dataSource={filteredData}
                pagination={{
                current: currentPage,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                }
              }} />

            </>
          )}

          </>
          ) : (
              <div className="text-center py-4">
                    <h5>No Data Found</h5>
              </div>
          )}


          </div>

    </div>
  );
  
  const TabContent2 = () => (
    <div className='row p-0 m-0' style={{border:"1px solid #B3C8CF"}}>

        <div className='row p-0 px-md-5 m-0'>

          <div className='row p-0 px-md-5 m-0 py-3 pb-5'>
                <div className='col-12 col-md-4 pt-2'>
                    <div className='row p-0 m-0'>
                          <div className='col-4 col-md-4 p-0'>
                              <label>Select Class :</label>
                          </div>
                          <div className='col-8 col-md-8'>
                              <Select className='w-100' showSearch
                                  value={selectedClass} placeholder="Select Class"
                                  onChange={(value) => setSelectedClass(value)}
                                  filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                  >
                                {classOptions.map((option) => (
                                  <Option key={option.class_id} value={option.class_id}>
                                    {option.classname}
                                  </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>From Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                            <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={fromDate1}  onChange={(date) => setFromDate1(date)} />
                        </div>
                    </div>
                </div> 
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>To Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                        <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={toDate1}  onChange={(date) => setToDate1(date)} />
                        </div>
                    </div> 
                </div> 
                <div className='col-12 col-md-2 pt-4 pt-md-2 d-flex align-items-center justify-content-center'>
                      <Button className='saveBtn' onClick={handleShowClassWise}>Show Data</Button>
                      <Button className='cancelBtn' onClick={handleCancel1}>Cancel</Button>
                </div>
          </div>

        </div>

         {/* ======>> table data <<====== */}
         <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

         {data1.length > 0 ? (
          <>
          {isLoading ? (
                <div className="text-center">
                  <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                </div>
             ) : (
            <>
              <div className='col-12 py-4 text-end'>
                  <Button className='downloadBtn' onClick={handleDownloadExcel1}> Download Excel</Button>
                  <Button className='downloadBtn' onClick={handleDownloadPdf1}>Download Pdf</Button>
              </div>
              
            <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                <div className='col-12 col-lg-6 text-start text-md-start'>
                <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                        <select onChange={(e) => setPageSize1(Number(e.target.value))} value={pageSize1}>
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                        </select>
                </div>
                <div className='col-12 col-lg-6 text-start text-md-end'>
                <span style={{fontWeight:500}}>Search &nbsp; </span>
                    <Input
                    placeholder="Search Here"
                    value={searchText1}
                    onChange={(e) => setSearchText1(e.target.value)}
                    style={{ width: 200 }}
                    />
                </div>                   
            </div>

              <Table style={{overflow:"auto"}} columns={columns1} 
                dataSource={filteredData1}
                pagination={{
                current: currentPage1,
                pageSize: pageSize1,
                onChange: (page, pageSize) => {
                  setCurrentPage1(page);
                  setPageSize1(pageSize);
                }
              }} />
          </>
        )}
          </>
          ) : (
              <div className="text-center py-4">
                    <h5>No Data Found</h5>
              </div>
          )}


          </div>

    </div>
  );
  
  const TabContent3 = () => (
    <div className='row p-0 m-0' style={{border:"1px solid #B3C8CF"}}>

      <div className='row p-0 px-md-5 m-0'>

        <div className='row p-0 px-md-5 m-0 py-3 pb-5'>

             <div className='col-12 col-md-6 col-lg-3 pt-2 px-1'>
                  <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0 d-flex align-items-center'>
                            <label>Select Class :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                            <Select className='w-100' showSearch
                                value={selectedClass1} placeholder="Select Class"
                                onChange={(value) => setSelectedClass1(value)}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                                >
                              {classOptions.map((option) => (
                                <Option key={option.class_id} value={option.class_id}>
                                  {option.classname}
                                </Option>
                              ))}
                          </Select>
                      </div>
                  </div>
              </div>

              <div className='col-12 col-md-6 col-lg-3 pt-2 px-1'>
                  <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0 d-flex align-items-center'>
                            <label>Select Subject :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                            <Select className='w-100' showSearch
                                value={selectedSubject} placeholder="Select Subject"
                                onChange={(value) => setSelectedSubject(value)}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                                >
                              {subjectOptions.map((option) => (
                                <Option key={option.sub_id} value={option.sub_id}>
                                  {option.subjectname}
                                </Option>
                              ))}
                          </Select>
                      </div>
                  </div>
              </div>

              <div className='col-12 col-md-6 col-lg-3 pt-2 px-1'>
                  <div className='row p-0 m-0'>
                      <div className='col-4 col-md-4 p-0 d-flex align-items-center'>
                            <label>From Date :</label>
                      </div>
                      <div className='col-8 col-md-8'>
                          <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={fromDate2}  onChange={(date) => setFromDate2(date)} />
                      </div>
                  </div>
              </div> 

              <div className='col-12 col-md-6 col-lg-3 pt-2 px-1'>
                  <div className='row p-0 m-0'>
                      <div className='col-4 col-md-4 p-0 d-flex align-items-center'>
                            <label>To Date :</label>
                      </div>
                      <div className='col-8 col-md-8'>
                      <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={toDate2}  onChange={(date) => setToDate2(date)} />
                      </div>
                  </div> 
              </div> 

              <div className='col-12 pt-4 pt-md-4 d-flex align-items-center justify-content-center'>
                    <Button className='saveBtn' onClick={handleShowSubjectWise}>Show Data</Button>
                    <Button className='cancelBtn' onClick={handleCancel2}>Cancel</Button>
              </div>
        </div>

      </div>

      {/* ======>> table data <<======
      <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

      {data2.length > 0 ? (
        <>
        {isLoading ? (
                <div className="text-center">
                  <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                </div>
             ) : (
            <>
            <div className='col-12 py-4 text-end'>
                <Button className='downloadBtn' onClick={handleDownloadExcel2}> Download Excel</Button>
                <Button className='downloadBtn' onClick={handleDownloadPdf2}>Download Pdf</Button>
            </div>
            
          <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
              <div className='col-12 col-lg-6 text-start text-md-start'>
              <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                      <select onChange={(e) => setPageSize2(Number(e.target.value))} value={pageSize2}>
                          <option value={10}>10</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                      </select>
              </div>
              <div className='col-12 col-lg-6 text-start text-md-end'>
              <span style={{fontWeight:500}}>Search &nbsp; </span>
                  <Input
                  placeholder="Search Here"
                  value={searchText2}
                  onChange={(e) => setSearchText2(e.target.value)}
                  style={{ width: 200 }}
                  />
              </div>                   
          </div>

            <Table style={{overflow:"auto"}} columns={columns2} 
              dataSource={filteredData2}
              pagination={{
              current: currentPage2,
              pageSize: pageSize2,
              onChange: (page, pageSize) => {
                setCurrentPage2(page);
                setPageSize2(pageSize);
              }
            }} />
          </>
        )}
        </>
        ) : (
            <div className="text-center py-4">
                  <h5>No Data Found</h5>
            </div>
        )}


        </div> */}

         {/* ======>> table data <<====== */}
         <div className="col-12 shadow" style={{ border: "1px solid #d3d3d3" }}>
            {data2.lectures && data2.lectures.length > 0 ? (
              <>
                {isLoading ? (
                  <div className="text-center">
                    <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                  </div>
                ) : (
                  <>
                    <div className="col-12 py-4 text-center text-md-end">
                      <Button className="downloadBtn" onClick={handleDownloadExcel2}>
                        Download Excel
                      </Button>
                      <Button className="downloadBtn" onClick={handleDownloadPdf2}>
                        Download Pdf
                      </Button>
                    </div>
                    <div className="row p-0 m-0 py-2" style={{ borderBottom: "1px solid grey" }}>
                      <div className="col-12 col-lg-6 text-start text-md-start">
                        <span style={{ fontWeight: 500 }}>Show entries &nbsp; </span>
                        <select onChange={(e) => setPageSize2(Number(e.target.value))} value={pageSize2}>
                          <option value={10}>10</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 text-start text-md-end">
                        <span style={{ fontWeight: 500 }}>Search &nbsp; </span>
                        <Input
                          placeholder="Search Here"
                          value={searchText2}
                          onChange={(e) => setSearchText2(e.target.value)}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    <Table
                      style={{ overflow: "auto" }}
                      dataSource={
                        data2.lectures
                          .flatMap((lecture) =>
                            lecture.rollNo
                              .filter((student) =>
                                student.studentname?.toLowerCase().includes(searchText2.toLowerCase())
                              )
                              .map((student) => ({
                                classname: lecture.classname,
                                subjectname: lecture.subjectname,
                                lec_date: lecture.lec_date,
                                lec_time: lecture.lec_time,
                                roll_no: student.roll_no,
                                reg_no: student.reg_no,
                                studentname: student.studentname,
                                flag: student.flag == 1 ? "Present" : "Absent",
                                description: lecture.description,
                              }))
                          )
                      }
                      pagination={{
                        current: currentPage2,
                        pageSize: pageSize2,
                        onChange: (page, pageSize) => {
                          setCurrentPage2(page);
                          setPageSize2(pageSize);
                        },
                      }}
                      columns={[
                        {
                          title: "Class Name",
                          dataIndex: "classname",
                        },
                        {
                          title: "Subject Name",
                          dataIndex: "subjectname",
                        },
                        {
                          title: "Date",
                          dataIndex: "lec_date",
                        },
                        {
                          title: "Time",
                          dataIndex: "lec_time",
                        },
                        {
                          title: "Roll No",
                          dataIndex: "roll_no",
                        },
                        {
                          title: "Reg No",
                          dataIndex: "reg_no",
                        },
                        {
                          title: "Student Name",
                          dataIndex: "studentname",
                        },
                        {
                          title: "Status",
                          dataIndex: "flag",
                        },
                        {
                          title: "Description",
                          dataIndex: "description",
                        },
                      ]}
                    />
                  </>
                )}
              </>
            ) : (
              <div className="text-center py-4">
                <h5>No Data Found</h5>
              </div>
            )}
          </div>




   </div>
  );

  const items = [
    {
      key: '1',
      label: 'All',
      children: <TabContent1 />,
    },
    {
      key: '2',
      label: 'Class Wise',
      children: <TabContent2 />,
    },
    {
      key: '3',
      label: 'Subject Wise',
      children: <TabContent3 />,
    },
  ];

  //===========================================================================

  return (
    <>
    <HeaderPage/>

    <div className='container-fluid'>

        <div className='row px-1 px-lg-5 mt-2 d-flex align-items-center'>
            <span className='componentTitle'> <AiOutlineAlignRight /> Attendance Details Report</span>
        </div>

        <div className='row m-0 px-lg-5 mb-5'>
            <div className='row m-0 mb-5 shadow px-lg-3 py-4' style={{border:"1px solid #d3d3d3"}}>

                              <div className='col-12 py-2'>
                                <Tabs defaultActiveKey="1" items={items}  />
                              </div>

            </div>
        </div>

    </div>

    <FooterPage/>
</>
  )
}

export default AttendanceDetailsReport