import React, { useState } from 'react'
import NSLLogo from '../../Assets/NSLLogo.png'
import './HeaderPageCSS.css';
import { Avatar, Menu, Dropdown, Modal } from 'antd';
import { UserOutlined, LogoutOutlined, LockOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
const { confirm } = Modal;

const HeaderPage = () => {

  const navigate = useNavigate(); 
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  //============== Menu items for dropdown ==============
  const showDeleteConfirm = () => {
    confirm({
      title: 'Confirm Logout',
      icon: <ExclamationCircleFilled />,
      content: 'Are you sure you want to Logout ?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
          sessionStorage.clear();
          navigate('/');
  
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        Hi! Admin
      </Menu.Item>
      <Menu.Item key="2" icon={<LogoutOutlined />} onClick={showDeleteConfirm}>
        Logout
      </Menu.Item>
    </Menu>
  );

 
//==============================================================================

  return (
    <>
      <div className='Container-fluid'>

        <div className='row p-0 m-0 header1'>
                <div className='col-12 col-md-1 py-2  d-flex justify-content-center align-items-center'>
                    <img src={NSLLogo}  style={{width:"100px",height:"100%"}}/>
                </div>
                <div className='col-12 col-md-11 py-2 d-flex justify-content-center align-items-center'>
                        <div className='row p-0 m-0 w-100'>
                                <div className='col-12 text-center fw-bold'>
                                    <h5>Latthe Education Society's</h5>
                                </div>
                                <div className='col-12 text-center fw-bolder' style={{marginTop:"-10px"}}>
                                    <h1>Narayandas Sarwottamdas Soti Law College, Sangli</h1>
                                </div>
                                <div className='col-12 text-center'>
                                    <h6>Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution</h6>
                                </div>
                        </div>
                </div>
        </div>

        <div className='row p-0 m-0 navBar'>
            <nav class="navbar navbar-expand-lg">
              <div class="container-fluid">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                  <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

                    <li class="nav-item">
                      <a class="nav-link text-white" aria-current="page" href="/#/dashboard">Dashboard</a>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Masters
                      </a>
                      <ul class="dropdown-menu">
                        <li><a class="dropdown-item" href="/#/m-cm">Class Master</a></li>
                        <li><a class="dropdown-item" href="/#/m-subm">Subject Master</a></li>
                        <li><a class="dropdown-item" href="/#/m-sm">Student Master</a></li>
                        <li><a class="dropdown-item" href="/#/m-em">Employee Master</a></li>
                      </ul>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link text-white" href="/#/sa">Sub.Allotment</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link text-white" href="/#/cl">Created Lectures</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link text-white" href="/#/rs">Registered Students</a>
                    </li>
                    <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Reports
                      </a>
                      <ul class="dropdown-menu dropMenu1">
                        <li><a class="dropdown-item" href="/#/r-ldr">Lecture Data</a></li>
                        <li><a class="dropdown-item" href="/#/r-asr">Attendance Summary</a></li>
                        <li><a class="dropdown-item" href="/#/r-adr">Attendance Details</a></li>
                        <li><a class="dropdown-item" href="/#/r-sldr">Subject Lecture Data</a></li>
                      </ul>
                    </li>

                    <li class="nav-item d-flex align-items-center">
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Avatar
                            style={{
                              backgroundColor: '#41c1fd',
                              cursor: 'pointer', // To indicate it's clickable
                            }}
                            icon={<UserOutlined />}
                          />
                      </Dropdown>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </nav>
        </div>

      </div>
    </>
  )
}

export default HeaderPage