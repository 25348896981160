import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { AiOutlineAlignRight } from 'react-icons/ai'
import { DatePicker, Form, Input, Modal, Select, Table } from 'antd'
import axios from 'axios'
import { FaUserEdit } from 'react-icons/fa'
import loaderImg from '../../Assets/loader1.gif'
import { classMasterGet, registeredStudentGet, registeredStudentUpdateStatus } from '../../APIs/API'
import { Option } from 'antd/es/mentions'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

const RegisteredStudents = () => {

  const navigate = useNavigate();
  //========== Check for session storage and redirect if empty======
  useEffect(() => {
    const token = sessionStorage.getItem('token'); 

    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const dateFormatList = ['DD/MM/YYYY'];

  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const [isLoading, setIsLoading] = useState(false);

     // =====================TABLE CODE=========================
     const [data, setData] = useState([]);
     const [filteredData, setFilteredData] = useState([]);
     const [searchText, setSearchText] = useState('');
     const [pageSize, setPageSize] = useState(10);
     const [currentPage, setCurrentPage] = useState(1);

     const [selectedStatus, setSelectedStatus] = useState(0);

      // Modal state
      const [isModalVisible, setIsModalVisible] = useState(false);
      const [editRecord, setEditRecord] = useState(null);
      const [classOptions, setClassOptions] = useState([]);
      
      const [firstName, setFirstName] = useState(null);
      const [middleName, setMiddleName] = useState(null);
      const [lastName, setLastName] = useState(null);
      const [emailId, setEmailId] = useState(null);
      const [emailError, setEmailError] = useState('');
      const [mobileNumber, setMobileNumber] = useState(null);
      const [regNo, setRegNo] = useState(null);
      const [selectClassName, setSelectClassName] = useState(null);
      const [selectStatus, setSelectStatus] = useState(null);
      const [dob, setDob] = useState(null);
      const [admissionDate, setAdmissionDate] = useState(null);

     //========== Fetch Table Data =========
     const FetchTableData = ()=>{
      setIsLoading(true);
         axios.get(registeredStudentGet)
         .then(response => {
          
             setData(response.data); 
             setFilteredData(response.data);

             setIsLoading(false);
         })
         .catch(error => {
             console.error('Error fetching class data:', error);
         });
       }

        // Fetch Class Options
      const FetchClassOptions = () => {
        axios
          .get(classMasterGet) // Assuming this is your API endpoint for classes
          .then((response) => {
            setClassOptions(response.data); // Set class options from API response
          })
          .catch((error) => {
            console.error('Error fetching class options:', error);
          });
      };

 
       useEffect(() => {
         FetchTableData();
         FetchClassOptions();
        }, []);
 
   
 
     const columns = [
       {
           title: 'Sr. No.',
           key: 'index',
           render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
         },
       {
           title: 'Member Id',
         dataIndex: 'reg_no',
         key: 'reg_no',
       },
       {
         title: 'Name', 
         key: 'name',
         render: (record) => `${record.fname} ${record.mname} ${record.lname}`,
       },
       {
         title: 'Class',
         dataIndex: 'classname',
         key: 'classname',
       },
       {
         title: 'Mobile No',
         dataIndex: 'mob_no',
         key: 'mob_no',
       },
       {
         title: 'Email Id',
         dataIndex: 'email_id',
         key: 'email_id',
       },
       {
         title: 'DOB',
         dataIndex: 'dateofbirth',
         key: 'dateofbirth',
       },
       {
         title: 'Admission Date',
         dataIndex: 'addmission_date',
         key: 'addmission_date',
       },
       
       {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color = '';
          let label = '';
    
          switch (status) {
            case 1:
              color = 'orange';
              label = 'Pending';
              break;
            case 2:
              color = 'green';
              label = 'Approved';
              break;
            case 3:
              color = 'red';
              label = 'Blocked';
              break;
            case 4:
              color = 'black';
              label = 'Cancel Member';
              break;
            default:
              label = 'Unknown';
          }
    
          return <span style={{ color }}>{label}</span>;
        },
      },
       {
         title: 'Action',
         key: 'action',
         render: (_, record) => (
             <FaUserEdit title='Edit' style={{fontSize:"18px",color:"orange",cursor:"pointer"}} onClick={()=>handleEdit(record)}/>
         ),
       },
     ];
 
     
 // Filter data based on search input
 const handleSearch = (searchText) => {
    const filtered = data.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setFilteredData(filtered);
};

   // Handle status filtering
   const filterByStatus = (status) => {
    setSelectedStatus(status);
  
    if (status === 0) {
      setFilteredData(data); // Show all data when 'ALL' is selected
    } else {
      const filtered = data.filter(item => item.status === status);
      setFilteredData(filtered);
    }
  };
  
  // Handle edit button click
  const handleEdit = (record) => {
    setEditRecord(record);
    console.log("==record==",record);
  
    // Set input values with the selected record's data
    setFirstName(record.fname || '');
    setMiddleName(record.mname || '');
    setLastName(record.lname || '');
    setEmailId(record.email_id || '');
    setMobileNumber(record.mob_no || '');
    setRegNo(record.reg_no || '');
    setSelectClassName(record.classname || null); // Assuming you get class name
    setSelectStatus(record.status || null); // Assuming you get the status
    setDob(record.dateofbirth ? moment(record.dateofbirth, 'DD-MM-YYYY') : null);
    setAdmissionDate(record.addmission_date ? moment(record.addmission_date, 'DD-MM-YYYY') : null);
  
    // Open modal
    setIsModalVisible(true);
  };

  // Handle modal submission
  const handleModalOk = async (values) => {

    const formattedDob = dob ? formatDate(new Date(dob)) : null;
    const formattedAdmissionDate = admissionDate ? formatDate(new Date(admissionDate)) : null;

    const updatedData = {
      fname: firstName,
      mname: middleName,
      lname: lastName,
      email_id: emailId,
      mob_no: mobileNumber,
      reg_no: regNo,
      dateofbirth: formattedDob,
      addmission_date: formattedAdmissionDate,
      class_id: selectClassName,
      status: selectStatus,
    };
    console.log("updatedData==>>",updatedData);

    try {
      const response = await axios.put(`${registeredStudentUpdateStatus}/${editRecord.id}`, updatedData); 
      console.log("response==>>==",response);
      if (response.data == "UPDATE") {
        toast.success("Student Data Updated Successfully ")
        setIsModalVisible(false);
        FetchTableData();
      }
      else{
        toast.warn("Error Occured While Updating Student Data !!")
      }
    } catch (error) {
      console.error('Error updating record:', error);
    }
  };

  // Handle modal cancellation
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const validateEmail = (email) => {
    // Basic email pattern validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmailId(value);

    // Check if email is valid
    if (!validateEmail(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError(''); 
    }
  };


   
  return (
    <>
        <HeaderPage/>

        <div className='container-fluid px-lg-5'>

                <div className='row px-2 px-lg-5 mt-2 d-flex align-items-center'>
                    <span className='componentTitle'> <AiOutlineAlignRight /> REGISTERED STUDENT</span>
                </div>

                <div className='row m-0 px-lg-5 mb-5'>        

                        {/* ======>> table data <<====== */}
                        <div className='col-12 shadow py-3' style={{border:"1px solid #d3d3d3"}}>

                        <div className='row px-lg-5'>
                            {/* Filter Buttons */}
                            <div className='col-12 my-3 text-center'>
                              <button className={`btn btn-outline-primary mt-2 ${selectedStatus === 0 ? 'active' : ''}`} onClick={() => filterByStatus(0)}>ALL</button>
                              <button className={`btn btn-outline-warning mt-2 mx-2 ${selectedStatus === 1 ? 'active' : ''}`} onClick={() => filterByStatus(1)}>PENDING</button>
                              <button className={`btn btn-outline-success mt-2 mx-2 ${selectedStatus === 2 ? 'active' : ''}`} onClick={() => filterByStatus(2)}>APPROVED</button>
                              <button className={`btn btn-outline-danger mt-2 mx-2 ${selectedStatus === 3 ? 'active' : ''}`} onClick={() => filterByStatus(3)}>BLOCKED</button>
                              <button className={`btn btn-outline-dark mt-2 mx-2 ${selectedStatus === 4 ? 'active' : ''}`} onClick={() => filterByStatus(4)}>CANCEL MEMBER</button>
                            </div>

                        </div>   

                     
                        {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                            <>
                            <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                                <div className='col-12 col-lg-6 text-start text-md-start'>
                                <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                                        <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                            <option value={10}>10</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                </div>
                                <div className='col-12 col-lg-6 text-start text-md-end'>
                                <span style={{fontWeight:500}}>Search &nbsp; </span>
                                <Input
                                    placeholder="Search Here"
                                    value={searchText}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                        handleSearch(e.target.value);
                                    }}
                                    style={{ width: 200 }}
                                />
                                </div>                   
                            </div>

                            <Table style={{overflow:"auto"}} columns={columns} 
                                dataSource={filteredData}
                                pagination={{
                                current: currentPage,
                                pageSize: pageSize,
                                onChange: (page, pageSize) => {
                                  setCurrentPage(page);
                                  setPageSize(pageSize);
                                }
                              }}                         
                            />
                            </>
                      )}
                        </div>

                        {/* Modal for editing student details */}
                          <Modal  width={1000}
                            title="Edit Student Details"
                            visible={isModalVisible}
                            onCancel={handleModalCancel}
                            onOk={handleModalOk}
                             okText="Update"
                          >

                          <div className='row py-2 border'>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>First Name</label>
                                <Input name="fname" value={firstName}   onChange={(e) => setFirstName(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Middle Name</label>
                                <Input name="mname" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Last Name</label>
                                <Input name="lname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Member ID (Reg No)</label>
                                <Input name="reg_no" value={regNo} onChange={(e) => setRegNo(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Mobile No</label>
                                <Input
                                  name="mob_no"
                                  value={mobileNumber}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                      setMobileNumber(value);
                                    }
                                  }}
                                  maxLength={10} 
                                />
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Email ID</label>
                                <Input name="email_id" value={emailId} onChange={handleEmailChange} />
                                {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Date of Birth</label>
                                <DatePicker className='w-100' format={dateFormatList}  value={dob}  onChange={(date) => setDob(date)} />
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Admission Date</label>
                                <DatePicker className='w-100' format={dateFormatList}  value={admissionDate}  onChange={(date) => setAdmissionDate(date)} />
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                  <label>Select Class</label> <br/>
                                            <Select className='w-100'
                                                value={selectClassName}
                                                onChange={(value) => setSelectClassName(value)}
                                            >
                                                {classOptions.map((option) => (
                                                    <Option key={option.class_id} value={option.class_id}>
                                                        {option.classname}
                                                    </Option>
                                                ))}
                                            </Select>
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Select Status</label> <br/>
                                <Select className='w-100'
                                        value={selectStatus}
                                        onChange={(value) => setSelectStatus(value)}
                                    >
                                        <Option value={1}>Pending</Option>
                                        <Option value={2}>Approved</Option>
                                        <Option value={3}>Blocked</Option>
                                        <Option value={4}>Cancel Member</Option>
                                    </Select>
                              </div>
                          </div>
                                   

                          </Modal>


                </div>
        </div>

        <FooterPage/>
    </>
  )
}

export default RegisteredStudents