import React, { useState } from 'react'
import NSLLogo from '../../Assets/NSLLogo.png'
import loadingImg from '../../Assets/loader1.gif'
import img from '../../Assets/4.png'
import './LoginCSS.css'
import { useNavigate } from 'react-router-dom';
import { RiLockPasswordLine } from 'react-icons/ri';
import { FaRegUser } from 'react-icons/fa';
import axios from 'axios';
import { adminLogin } from '../../APIs/API';
import { toast } from 'react-toastify';
import { VscDebugBreakpointData, VscDebugBreakpointLog } from 'react-icons/vsc';

const LoginPage = () => {

    const [username, setUsername] = useState(''); 
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false); 
    const navigate = useNavigate(); 
  
    const handleLogin = async (e) => {
      e.preventDefault(); // Prevent form submission from refreshing the page
      setLoading(true);
      const saveObj ={
          email_id: username,
          password: password,
      }
      try {
        const response = await axios.post(adminLogin,saveObj);        

            sessionStorage.setItem('userData', JSON.stringify(response.data.userdata));
            sessionStorage.setItem('token', response.data.token);
            
        if (response.data === 'NOTFOUND') {          
          toast.warn("Invalid login details !!")
        } else if (response.data.userdata !== null) {
            navigate('/dashboard'); 
        }else {
          alert('Invalid username or password');
        }
      } catch (error) {
        console.error('There was an error logging in!', error);
      }
      finally {
        setLoading(false); // Hide loader when login process completes
    }   
    };
  
//   const handleLogin = (e) => {
//       e.preventDefault(); // Prevent form submission from refreshing the page
  
//       const hardcodedUsername = 'admin';
//       const hardcodedPassword = '123';
  
//       if (username === hardcodedUsername && password === hardcodedPassword) {
//         navigate('/dashboard'); 
//       } else {
//         alert('Invalid username or password');
//       }
//     };
   const currentYear = new Date().getFullYear();
    
  const handleCancelAccount = () => {
    navigate('/c-member'); 
  };
  const handlePrivacyPolicy = () => {
    navigate('/pp'); 
  };


  return (
    <>
        <div className='Container-fluid'>

            <div className='row p-0 m-0 header1'>
                <div className='col-12 col-md-1 py-2  d-flex justify-content-center align-items-center'>
                    <img src={NSLLogo}  style={{width:"100px",height:"100%"}}/>
                </div>
                <div className='col-12 col-md-11 py-2 d-flex justify-content-center align-items-center'>
                        <div className='row p-0 m-0 w-100'>
                                <div className='col-12 text-center fw-bold'>
                                    <h5>Latthe Education Society's</h5>
                                </div>
                                <div className='col-12 text-center fw-bolder' style={{marginTop:"-10px"}}>
                                    <h1>Narayandas Sarwottamdas Soti Law College, Sangli</h1>
                                </div>
                                <div className='col-12 text-center'>
                                    <h6>Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution</h6>
                                </div>
                        </div>
                </div>
            </div>

            <div className='row p-0 m-0' style={{backgroundColor:"#dff3ff",height:"100vh",boxShadow: "0 -1px 3px rgba(0, 0, 0, 0.4)"}}>
                <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                    <img src={img} style={{width:"80%",}}/>
                </div>
                <div className='col-12 col-md-6  px-lg-5 pb-5 pb-md-0 wrapper d-flex justify-content-center align-items-center flex-column'>
                            <div className='row py-3'>
                                <div className="col-12 px-lg-5 text-center">
                                    <h1 style={{color:"#3598da",fontWeight:"bold"}}>LOGIN</h1>
                                </div>
                                <div className='col-12 px-lg-5'>
                                    <form onSubmit={handleLogin}>
                                        <div className="row px-lg-5">
                                        <i><FaRegUser /></i>
                                        <input 
                                            type="text" 
                                            placeholder="Username" 
                                            required 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)} // Handle input change
                                        />
                                        </div>
                                        <div className="row px-lg-5">
                                        <i><RiLockPasswordLine /></i>
                                        <input 
                                            type="password" 
                                            placeholder="Password" 
                                            required 
                                            value={password} 
                                            onChange={(e) => setPassword(e.target.value)} // Handle input change
                                        />
                                        </div>
                                        <div className="row px-lg-5 button">
                                        <input type="submit" value="Login" disabled={loading} />
                                        </div>
                                        {loading && (
                                            <div className="row px-lg-5 text-center">
                                                <div className="loader"><img src={loadingImg} alt='Loading...'/></div>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>  

                </div>
            </div>

            <div className='col-12 ' style={{backgroundColor:"#3598da"}}>
                        <div className='row w-100 p-0 m-0 py-2 pt-3'>
                            <div className='col-12 col-md-2'></div>
                            <div className='col-12 col-md-5'>
                                <div><span className='fw-bold'><VscDebugBreakpointLog /> Email :</span>&nbsp;<span>ns_law@rediffmail.com</span></div>
                                <div><span className='fw-bold'><VscDebugBreakpointLog /> Contact  :</span>&nbsp;<span>0233 237 2182</span></div>
                                <div><span className='fw-bold'><VscDebugBreakpointLog /> Address :</span>&nbsp;<span>Rajnemi Compus, Wood House Road, Sangli., Pin- 416416</span></div>
                            </div>
                            <div className='col-12 col-md-3'>
                                <div><span style={{cursor:"pointer"}} onClick={handlePrivacyPolicy}><VscDebugBreakpointData />&nbsp;Privacy Policy</span></div>
                                <div><span style={{cursor:"pointer"}}><VscDebugBreakpointData />&nbsp;Terms & Conditions</span></div>
                                <div><span style={{cursor:"pointer"}} onClick={handleCancelAccount}><VscDebugBreakpointData />&nbsp;Cancel Your Account</span></div>
                            </div>
                            <div className='col-12 col-md-2'></div>
                        </div>
                        <div className='row p-0 m-0 pb-3  pt-2 d-flex text-center align-items-end border-top'>
                            <span style={{fontSize:"13px"}}> &copy; {currentYear} Narayandas Sarwottamdas Soti Law College, Sangli | Developed By : TechnoAarv Solution</span>
                        </div>
                </div>

        </div>
    </>
  )
}

export default LoginPage