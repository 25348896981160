import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import NSLLogo from '../../../Assets/NSLLogo.png'
import { Button, Input } from 'antd';
import { cancelMemberApi } from '../../../APIs/API';
import { toast } from 'react-toastify';
import axios from 'axios';
import FooterPage from '../FooterPage';

const CancelMember = () => {

    const navigate = useNavigate();

    const [username,setUsername]=useState("");
    const [password,setPassword]=useState("");

    const handleBackToLogin =()=>{
        navigate('/'); 
    }

    // Handle Cancel =========
    const handleCancel = () => {
        setUsername(null);
        setPassword(null);
    };

    // Handle delete member data =========
    const handleDeleteMember = async () => {

        if (!username || !password ) {
            toast.error("Please select all fields");
            return;
        }

        const saveObj ={
            email_id: username,
            password: password,
        }
        

        try {
          const response = await axios.post(cancelMemberApi,saveObj);
              
          if (response.data === 'NOTFOUND') {          
            toast.warn("Data not found !!")
          } else if (response.data.message === 'Cancellation successful') {
            handleCancel();
            toast.success("Member Cancellation Successfully !!")
          }else if (response.data == "ERROR") {
            toast.warn("Some Error Occurred !!")
          } else {
            toast.warn("Some Error Occurred !!")
          }
        } catch (error) {
          console.error('Some Error Occurred !!', error);
        }
    };


  return (
    <>
      <div className='Container-fluid'>

        <div className='row p-0 m-0 header1'>
                <div className='col-12 col-md-1 py-2  d-flex justify-content-center align-items-center'>
                    <img src={NSLLogo}  style={{width:"100px",height:"100%"}}/>
                </div>
                <div className='col-12 col-md-11 py-2 d-flex justify-content-center align-items-center'>
                        <div className='row p-0 m-0 w-100'>
                                <div className='col-12 text-center fw-bold'>
                                    <h5>Latthe Education Society's</h5>
                                </div>
                                <div className='col-12 text-center fw-bolder' style={{marginTop:"-10px"}}>
                                    <h1>Narayandas Sarwottamdas Soti Law College, Sangli</h1>
                                </div>
                                <div className='col-12 text-center'>
                                    <h6>Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution</h6>
                                </div>
                        </div>
                </div>
        </div>

        <div className='row py-2 px-2 m-0 navBar text-end'>
                <span className='text-white fw-bold' style={{cursor:"pointer"}} onClick={handleBackToLogin}> Back </span>
        </div>

        <div className='row p-0 m-0 py-5 px-2'>
                <div className='col-12 col-md-2 col-lg-3'></div>
                <div className='col-12 col-md-8 col-lg-6 py-5 '>
                        <div className='row'>
                            <h2>Delete Your Account</h2>
                        </div>
                        <div className='row border py-5 px-lg-5 shadow'>
                            <div className='col-12 col-md-6 pt-2'>
                                        <label>Username</label> <br/>
                                         <Input 
                                            type="text" 
                                            placeholder="Username" 
                                            required 
                                            value={username} 
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                            </div>
                            <div className='col-12 col-md-6 pt-2'>
                                        <label>Password</label> <br/>
                                        <Input 
                                            type="password" 
                                            placeholder="Password" 
                                            required 
                                            value={password} 
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                            </div>
                            <div className='col-12 py-4 text-center'>
                                <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
                                <Button className='saveBtn' onClick={handleDeleteMember}>Save</Button>
                            </div>
                        </div>
                </div>
                <div className='col-12 col-md-2 col-lg-3'></div>
        </div>

        <FooterPage/>

      </div>
    </>
  )
}

export default CancelMember