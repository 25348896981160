// export const BaseIp = "http://192.168.29.112:8090/"

export const BaseIp = 'https://nsattendance.co.in/';




export const adminLogin = BaseIp + "api/admins/login"


//===== SUBJECT ALLOTMENT FORM ===========================================
export const subjectAllotmentSave = BaseIp + "api/classtoSub/add"  // Subject Allotment Save API (POST) (res = SAVED) (body = data)
export const subjectAllotmentGet = BaseIp + "api/classtoSub/findall"  // Subject Allotment Get API (GET)
export const subjectAllotmentUpdate = BaseIp + "api/classtoSub/update"  // Subject Allotment Update API (PUT) (params = id , body = data)
export const subjectAllotmentDelete = BaseIp + "api/classtoSub/delete"  // Subject Allotment Delete API (DELETE) (res = DELETE) (params = id)

export const semesterGet = BaseIp + "api/semister/findall"  // Semester Get All Data API (GET)
export const subjectGet = BaseIp + "api/subject_master/findall"  // Subject Get All Data API (GET)

//==========CREATED LECTURES===============
export const createdLectureGet = BaseIp + "api/manually/lecture-createlist"  // Created Lectures Data API (GET) (params : fromdate, todate)
export const createdLectureStatusUpdate = BaseIp + "api/manually/lecture-ActiveInactive"  //  Data API (PUT) (paramas : lec_id :1 ,lec_status : 1 // 1:Active,2:InActive)

// =====MASTERS=========================================================================

//========CLASS MASTER============
export const classMasterGet = BaseIp + "api/class_master/findall"  // Class Master Get All Data API (GET)
export const classMasterSave = BaseIp + "api/class_master/create"  // Class Master Save Data API (POST)
export const classMasterUpdate = BaseIp + "api/class_master/update"  // Class Master Update Data API (PUT) (body = class_id ,classname)
export const classMasterDelete = BaseIp + "api/class_master/delete"  // Class Master Delete Data API (DELETE) (body = class_id)

//========SUBJECT MASTER============
export const subjectMasterGet = BaseIp + "api/subject_master/findall"  // Subject Master Get All Data API (GET)
export const subjectMasterSave = BaseIp + "api/subject_master/create"  // Subject Master Save Data API (POST)
export const subjectMasterUpdate = BaseIp + "api/subject_master/update"  // Subject Master Update Data API (PUT) (body = sub_id ,classname)
export const subjectMasterDelete = BaseIp + "api/subject_master/delete"  // Subject Master Delete Data API (DELETE) (body = sub_id)

//========STUDENT MASTER============
export const studentMasterGet = BaseIp + "api/student_master/findall"  // Student Master Get All Data API (GET)
export const studentMasterSave = BaseIp + "api/student_master/create"  // Student Master Save Data API (POST) (ALREADYEXIST, NOTFOUND, SAVED)
export const studentMasterUpdate = BaseIp + "api/student_master/update"  // Student Master Update Data API (PUT) (body = id ,all data)
export const studentMasterDelete = BaseIp + "api/student_master/delete"  // Student Master Delete Data API (DELETE) (body = id) (DELETE, NOTFOUND, ERROR)

//========EMPLOYEE MASTER============
export const employeeMasterGet = BaseIp + "api/teacher_master/findall"  // Employee Master Get All Data API (GET)
export const employeeMasterSave = BaseIp + "api/teacher_master/create"  // Employee Master Save Data API (POST) (ALREADYEXIST, NOTFOUND, SAVED)
export const employeeMasterUpdate = BaseIp + "api/teacher_master/update"  // Employee Master Update Data API (PUT) (body = teacher_id ,all data)
export const employeeMasterDelete = BaseIp + "api/teacher_master/delete"  // Employee Master Delete Data API (DELETE) (body = teacher_id) (DELETE, NOTFOUND, ERROR)


//========Registered Student Data============
export const registeredStudentGet = BaseIp + "api/students/findAll"  // Registered Student Data Get API (GET)
export const registeredStudentUpdateStatus = BaseIp + "api/students/profile-update"  // Registered Student Data Update Status API (PUT) (params - id)


//========Admin Dashoard API's============
export const dashboardCountGet = BaseIp + "api/dashboard/count"  // Dashboard Count Get API (GET)
export const totalAttendanceDataGet = BaseIp + "api/dashboard/totalattendance"  // Total Attendance Data Get API (GET)
export const todaysAttendanceDataGet = BaseIp + "api/dashboard/todaycount"  // Todays Attendance Data Get API (GET)


//=========================================================================
//========================REPORTS API's================================================
//=========================================================================

//==========Lecture Data (Report)==================
export const allLectureDataGet = BaseIp + "api/report/All-lectureData"  // All Lecture Data Get API (GET) (params : from, to)
export const classLectureDataGet = BaseIp + "api/report/lectureDataClass"  // All Lecture Data Get API (GET) (params : Class , from, to)
export const employeeLectureDataGet = BaseIp + "api/report/lecture-teacherData"  // All Lecture Data Get API (GET) (params : teacher_id, from, to)

//==========Attendance Details Data (Report)==================
export const allAttendanceDetailsGet = BaseIp + "api/report/attendanceDetailsAll"  // All Attendance Details Get API (GET) (params : from, to)
export const classAttendanceDetailsGet = BaseIp + "api/report/attendanceDetailsClass"  // All Attendance Details Get API (GET) (params : Class , from, to)
export const subjectAttendanceDetailsGet = BaseIp + "api/report/total-presentabsent"  // All Attendance Details Get API (GET) (params : class_id, sub_id, from, to)


//==========Attendance Summery (Report)==================
export const AttendanceSummeryDataGet = BaseIp + "api/report/attendanceSummerydata"  //(GET)
 // (subject wise) ===> Attendance Summery Data Get API (GET) (params : class_id,flag:1  sub_id,from,to ) (date in =>  yyyy-mm-dd)
 // (All subject wise) ===> Attendance Summery Data Get API (GET) (params : class_id,flag:2  sub_id:0,from,to ) (date in =>  yyyy-mm-dd)

//==========Subject Lecture Data (Report)==================
export const subjectLectureDataGet = BaseIp + "api/report/sub-lecturedata"  // Subject Lecture Data Get API (GET) (params : class_id, sub_id, teacher_id)


//========CANCEL MEMBER API=================================================================
export const cancelMemberApi = BaseIp + "api/cancelmember/cancel" // cancel member post api (login page)