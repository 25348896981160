import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import NSLLogo from '../../../Assets/NSLLogo.png'
import { Button, Input } from 'antd';
import { cancelMemberApi } from '../../../APIs/API';
import { toast } from 'react-toastify';
import axios from 'axios';
import FooterPage from '../FooterPage';
import { VscDebugBreakpointData, VscDebugBreakpointLogUnverified } from 'react-icons/vsc';

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

    const navigate = useNavigate();

    const handleBackToLogin =()=>{
        navigate('/'); 
    }


  return (
    <>
      <div className='Container-fluid'>

        <div className='row p-0 m-0 header1'>
                <div className='col-12 col-md-1 py-2  d-flex justify-content-center align-items-center'>
                    <img src={NSLLogo}  style={{width:"100px",height:"100%"}}/>
                </div>
                <div className='col-12 col-md-11 py-2 d-flex justify-content-center align-items-center'>
                        <div className='row p-0 m-0 w-100'>
                                <div className='col-12 text-center fw-bold'>
                                    <h5>Latthe Education Society's</h5>
                                </div>
                                <div className='col-12 text-center fw-bolder' style={{marginTop:"-10px"}}>
                                    <h1>Narayandas Sarwottamdas Soti Law College, Sangli</h1>
                                </div>
                                <div className='col-12 text-center'>
                                    <h6>Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution</h6>
                                </div>
                        </div>
                </div>
        </div>

        <div className='row py-2 px-2 m-0 navBar text-end'>
                <span className='text-white fw-bold' style={{cursor:"pointer"}} onClick={handleBackToLogin}> Back </span>
        </div>

        <div className='row p-0 m-0 py-5 px-2'>
                <div className='col-12 col-md-1'></div>
                <div className='col-12 col-md-10 py-5'>

                        <div className='row border shadow p-3'>
                           <h2> Privacy Policy</h2>
                        
                            <b className='pt-3'>Last updated: January 25, 2024</b>
                            <p className='pt-3'>This privacy notice for Narayandas Sarwottamdas Soti Law College, Sangli ("<b>we</b>," "<b>us</b>," or "<b>our</b>"), describes how and why we might collect, store, use, and/or share (" process ") your information when you use our services (" Services "), such as when you:</p>
                            <p><VscDebugBreakpointLogUnverified /> Download and use our mobile application (NSL Qr Attendance ), or any other application of ours that links to this privacy notice</p>
                            <p><VscDebugBreakpointLogUnverified /> Engage with us in other related ways, including any sales, marketing, or events</p>
                            <p><b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at <span className='text-primary'>technoaarv@gmail.com.</span></p>

                            <h4 className='pt-3'><i>SUMMARY OF KEY POINTS</i></h4>
                            <p><b>Do we process any sensitive personal information?</b> We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law. Learn more about sensitive information we process.</p>
                            <p><b>Do we receive any information from third parties?</b> We may receive information from public databases, marketing partners, social media platforms, and other outside sources. Learn more about information collected from other sources.</p>
                            <p><b>How do we process your information?</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process your information.</p>
                            <p><b>In what situations and with which parties do we share personal information?</b> We may share information in specific situations and with specific third parties. Learn more about when and with whom we share your personal information.</p>
                            <p><b>How do we keep your information safe?</b> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.</p>
                            <p><b>What are your rights?</b> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your privacy rights.</p>
                            <p><b>How do you exercise your rights?</b> The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
                            <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>
                            <p><b>What personal information do we process?</b> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about personal information you disclose to us.</p>

                            <h4 className='pt-3'>TABLE OF CONTENTS</h4>
                            <p>1. WHAT INFORMATION DO WE COLLECT?</p>
                            <p>2. HOW DO WE PROCESS YOUR INFORMATION?</p>
                            <p>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
                            <p>4. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
                            <p>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
                            <p>6. DO WE COLLECT INFORMATION FROM MINORS?</p>
                            <p>7. WHAT ARE YOUR PRIVACY RIGHTS?</p>
                            <p>8. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
                            <p>9. DO WE MAKE UPDATES TO THIS NOTICE?</p>
                            <p>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
                            <p>11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>

                            <h4 className='pt-3'><i>1. WHAT INFORMATION DO WE COLLECT?</i></h4>
                            <p><b>Personal information you disclose to us</b></p>
                            <p><b>In Short:</b> We collect personal information that you provide to us</p>
                            <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
                            <p><b>Personal Information Provided by You.</b> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                            <p><VscDebugBreakpointData /> names</p>
                            <p><VscDebugBreakpointData /> phone numbers</p>
                            <p><VscDebugBreakpointData /> email addresses</p>
                            <p><VscDebugBreakpointData /> mailing addresses</p>
                            <p><VscDebugBreakpointData /> passwords</p>
                            <p><VscDebugBreakpointData /> usernames</p>
                            <p><VscDebugBreakpointData /> contact or authentication data</p>
                            <p><VscDebugBreakpointData /> contact preferences</p>

                            <p className='pt-4'><i>Sensitive Information. When necessary, with your consent or as otherwise permitted by applicable law, we process the following categories of sensitive information:</i></p>

                            <p><VscDebugBreakpointData /> student data</p>
                            <p>Application Data. If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
                            <p><VscDebugBreakpointData /> Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.</p>
                            <p><VscDebugBreakpointData /> Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, and other features. If you wish to change our access or permissions, you may do so in your device's settings.</p>
                            <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.</p>
                            <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                            <p><b>Information automatically collected</b></p>

                            <p><b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.</p>
                            <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>

                            <p className='py-2'>The information we collect includes:</p>

                            <p><VscDebugBreakpointData /> Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</p>

                            <p className='py-2'><b>Information collected from other sources</b></p>
                            <p className='py-2'><b>In Short:</b> We may collect limited data from public databases, marketing partners, and other outside sources.</p>
                            <p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other sources, such as public databases, joint marketing partners, affiliate programs, data providers, and from other third parties. This information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion</p>


                            <h4 className='pt-3'><i>2. HOW DO WE PROCESS YOUR INFORMATION?</i></h4>
                            <p><b>In Short:</b> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.</p>
                            <p><b>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</b></p>
                            <p><b>To facilitate account creation and authentication and otherwise manage user accounts.</b> We may process your information so you can create and log in to your account, as well as keep your account in working order.</p>

                            <h4 className='pt-3'><i>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</i></h4>
                            <p><b>In Short:</b> We may share information in specific situations described in this section and/or with the following third parties.</p>
                            <p>We may need to share your personal information in the following situations:</p>
                            <p><VscDebugBreakpointData />  Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</p>


                            <h4 className='pt-3'><i>4. HOW LONG DO WE KEEP YOUR INFORMATION?</i></h4>
                            <p><b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</p>
                            <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.</p>
                            <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

                            <h4 className='py-3'><i>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</i></h4>
                            <p><b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.</p>
                            <p>We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.</p>

                            <h4 className='py-3'><i>6. DO WE COLLECT INFORMATION FROM MINORS?</i></h4>
                            <p><b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.</p>
                            <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span className='text-primary'>technoaarv@gmail.com.</span></p>

                            <h4 className='pt-3'><i>7. WHAT ARE YOUR PRIVACY RIGHTS?</i></h4>
                            <p><b>In Short:</b> You may review, change, or terminate your account at any time.</p>
                            <p><b>Withdrawing your consent:</b> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
                            <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>

                            <p className='py-2'><b>Account Information</b></p>

                            <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>

                            <p> Log in to your account settings and update your user account.</p>
                            <p> Contact us using the contact information provided.</p>

                            <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.</p>
                            <p>If you have questions or comments about your privacy rights, you may email us at <span className='text-primary'>technoaarv@gmail.com</span>.</p>

                            <h4 className='pt-3'><i>8. CONTROLS FOR DO-NOT-TRACK FEATURES</i></h4>
                            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>

                            <h4 className='pt-3'><i>9. DO WE MAKE UPDATES TO THIS NOTICE?</i></h4>
                            <p><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
                            <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>

                            <h4 className='pt-3'><i>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</i></h4>
                            <p>If you have questions or comments about this notice, you may email us at <span className='text-primary'>technoaarv@gmail.com</span> or contact us by post at:</p>
                            <p>Narayandas Sarwottamdas Soti Law College, Sangli</p>
                            <p>Sangli, Maharashtra, India</p>

                            <h4 className='pt-3'><i>11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</i></h4>
                            <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.</p>
                           
                        </div>

                </div>
                <div className='col-12 col-md-1'></div>
        </div>

        <FooterPage/>

      </div>
    </>
  )
}

export default PrivacyPolicy