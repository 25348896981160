import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { classMasterGet,  studentMasterDelete, studentMasterGet, studentMasterSave, studentMasterUpdate } from '../../APIs/API';
import { MdEditDocument } from 'react-icons/md';
import { AiOutlineAlignRight  } from 'react-icons/ai';
import { DatePicker, Button, Input, Modal, Select, Table, ConfigProvider } from 'antd'
import { MdDelete } from 'react-icons/md';
import axios from 'axios';
import { Option } from 'antd/es/mentions'
import moment from 'moment'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import loaderImg from '../../Assets/loader1.gif'

const StudentMaster = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
    //========== Check for session storage and redirect if empty======
    useEffect(() => {
      const token = sessionStorage.getItem('token'); 
  
      if (!token) {
        navigate('/');
      }
    }, [navigate]);

  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const formatDate1 = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  //==================================================================================
      const [data, setData] = useState([]);
      const [classOptions, setClassOptions] = useState([]);
      const [open, setOpen] = useState(false);

      const [firstName, setFirstName] = useState(null);
      const [middleName, setMiddleName] = useState(null);
      const [lastName, setLastName] = useState(null);
      const [emailId, setEmailId] = useState(null);
      const [emailError, setEmailError] = useState('');
      const [mobileNumber, setMobileNumber] = useState(null);
      const [regNo, setRegNo] = useState(null);
      const [selectClassName, setSelectClassName] = useState(null);
      const [rollNumber, setRollNumber] = useState(null);
      const [dob, setDob] = useState(null);
      const [admissionDate, setAdmissionDate] = useState(null);
  
      const [searchText, setSearchText] = useState('');
      const [pageSize, setPageSize] = useState(10);
      const [currentPage, setCurrentPage] = useState(1);

      const [deleteModalVisible, setDeleteModalVisible] = useState(false);
      const [selectedRecord, setSelectedRecord] = useState(null);
      const [isEditing, setIsEditing] = useState(false);  // New state to check if editing
      const [selectedId, setSelectedId] = useState(null);  // To store selected record ID


      const validateEmail = (email) => {
        // Basic email pattern validation
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
      };
    
      const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmailId(value);
    
        // Check if email is valid
        if (!validateEmail(value)) {
          setEmailError('Please enter a valid email address.');
        } else {
          setEmailError(''); 
        }
      };


      //========== Fetch Table Data =========
      const FetchTableData = ()=>{
        setIsLoading(true);
        axios.get(studentMasterGet)
        .then(response => {
            setData(response.data); 
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching class data:', error);
        });
      }

          // Fetch Class Options
          const FetchClassOptions = () => {
            axios
              .get(classMasterGet) // Assuming this is your API endpoint for classes
              .then((response) => {
                setClassOptions(response.data); // Set class options from API response
              })
              .catch((error) => {
                console.error('Error fetching class options:', error);
              });
          };
    
     
           useEffect(() => {
             FetchTableData();
             FetchClassOptions();
            }, []);
            

       // Handle Save button click
       const handleSaveOrUpdate = async () => {
        if (!firstName || !middleName || !lastName || !emailId || !mobileNumber || !regNo || !selectClassName || !rollNumber || !dob || !admissionDate) {
            toast.error("Please select all fields before saving");
            return;
        }       
  
            // Format dates to dd/mm/yyyy
          const formattedDob = dob ? formatDate1(new Date(dob)) : null;
          const formattedAdmissionDate = admissionDate ? formatDate(new Date(admissionDate)) : null;


        if (isEditing) {

          const payload = {
            id:selectedId,
            fname: firstName,
            mname: middleName,
            lname: lastName,
            email_id: emailId,
            mob_no: mobileNumber,
            reg_no: regNo,
            class_id: selectClassName,
            roll_no: rollNumber,
            dateofbirth: formattedDob,
            addmission_date: formattedAdmissionDate,
           };

           

            try {
                const response = await axios.put(studentMasterUpdate, payload);
                if (response.data === "UPDATE") {
                    toast.success('Data updated successfully');
                    FetchTableData(); 
                    handleCancel(); 
                }  else if (response.data === 'ALREADYEXIST') {
                  toast.warn('Data already exist!!');
                } else {
                    toast.error('Failed to update data');
                }
            } catch (error) {
                console.error('Error updating data:', error);
                toast.error('An error occurred while updating');
            }
        } else {

          const payload1 = {
            fname: firstName,
            mname: middleName,
            lname: lastName,
            email_id: emailId,
            mob_no: mobileNumber,
            reg_no: regNo,
            class_id: selectClassName,
            roll_no: rollNumber,
            dateofbirth: formattedDob,
            addmission_date: formattedAdmissionDate,
           };  
           
           

            try {
                const response = await axios.post(studentMasterSave, payload1);
                
                if (response.data === "SAVED") {
                    toast.success('Data saved successfully');
                    FetchTableData(); 
                    handleCancel(); 
                } else if (response.data === "ALREADYEXIST") {
                    toast.warn('Data Already Exist');
                } else {
                    toast.error('Failed to save data');
                }
            } catch (error) {
                console.error('Error saving data:', error);
                toast.error('An error occurred while saving');
            }
        }
    };

    // Handle Cancel button click
    const handleCancel = () => {
      setFirstName(null);
      setMiddleName(null);
      setLastName(null);
      setEmailId(null);
      setMobileNumber(null);
      setRegNo(null);
      setSelectClassName(null);
      setRollNumber(null);
      setAdmissionDate(null);
      setDob(null);

      setOpen(false); 
      setIsEditing(false);  // Reset editing state
      setSelectedId(null);  // Clear selected ID
  };

    const cancelModal = () =>{
      setOpen(false);
      handleCancel();
    }
  
    //==Handle Edit Data= ====
    const handleEditClick = (record) => {
      
      setOpen(true); 
      setIsEditing(true); 
      setSelectedId(record.id || '');
      setFirstName(record.fname || '');
      setMiddleName(record.mname || '');
      setLastName(record.lname || '');
      setEmailId(record.email_id || '');
      setMobileNumber(record.mob_no || '');
      setRegNo(record.reg_no || '');
      setSelectClassName(record.class_id || null);
      setRollNumber(record.roll_no || null); 
      setAdmissionDate(record.addmission_date ? moment(record.addmission_date, 'DD/MM/YYYY') : null); 
      setDob(record.dateofbirth ? moment(record.dateofbirth, 'DD/MM/YYYY') : null);
  };

     // Handle delete button click
     const handleDeleteClick = (record) => {      
      setSelectedRecord(record.id); 
      setDeleteModalVisible(true); 
  };
    // Handle delete confirmation
    const handleDeleteConfirm = async () => {
      const Id = {
        id : selectedRecord
      }
      
      try {        
          const response = await axios.delete(studentMasterDelete , { data: Id });
          
          if (response.data === 'DELETE') {
              toast.success('Data Deleted Successfully');
              FetchTableData(); 
              setDeleteModalVisible(false); 
          }else if(response.data === "ERROR"){
              toast.warn('Some error occured!!'); 
          }
          else{
            toast.warn('Some error occured!!'); 
          }
          
      } catch (error) {
          console.error('Error deleting data:', error);
          toast.error('Failed to delete data');
      }
  };

    // Cancel deletion
    const handleDeleteCancel = () => {
        setDeleteModalVisible(false);
    };

  // =====================TABLE CODE=========================

  const columns = [
    {
      title: 'Sr. No.',
      key: 'index',
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
        title: 'Member Id',
      dataIndex: 'reg_no',
      key: 'reg_no',
    },
    {
      title: 'Student Name', 
      key: 'name',
      render: (record) => `${record.fname} ${record.mname} ${record.lname}`,
    },
    {
      title: 'Class Name',
      dataIndex: 'class_name',
      key: 'class_name',
    },
    {
      title: 'Mobile No',
      dataIndex: 'mob_no',
      key: 'mob_no',
    },
    {
      title: 'Email Id',
      dataIndex: 'email_id',
      key: 'email_id',
    },
    {
      title: 'DOB',
      dataIndex: 'dateofbirth',
      key: 'dateofbirth',
    },
    {
      title: 'Admission Date',
      dataIndex: 'addmission_date',
      key: 'addmission_date',
    },
    {
      title: 'Roll No',
      dataIndex: 'roll_no',
      key: 'roll_no',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <>
            <MdEditDocument title='Edit' style={{fontSize:"20px",color:"orange",cursor:"pointer"}}  onClick={() => handleEditClick(record)}/> &nbsp;&nbsp;&nbsp;
            <MdDelete title='Delete' style={{fontSize:"20px",color:"red",cursor:"pointer"}}  onClick={() => handleDeleteClick(record)}/>
          </>
      ),
    },
  ];


      // Filter data based on search input
      const filteredData = data.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
      );
      
      
//=========================================================

  return (
    <>
    <HeaderPage/>

    <div className='container-fluid px-lg-5'>

        <div className='row px-3 px-lg-5 mt-2 d-flex align-items-center'>
            <span className='componentTitle'> <AiOutlineAlignRight /> STUDENT MASTER</span>
        </div>

        <div className='row m-0 px-lg-5 mb-5'>

            <div className='row m-0 mb-5 shadow px-lg-3 py-4' style={{border:"1px solid #d3d3d3"}}>

                <div className='col-12 p-0 py-2'>
                    <div>
                      <button className=' newEntry' onClick={() => setOpen(true)}>New Entry</button>
                    </div>
                </div>

                {/* ======>> table data <<====== */}
                <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

                <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                    <div className='col-12 col-lg-6 text-start text-md-start'>
                    <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                            <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                    </div>
                    <div className='col-12 col-lg-6 text-start text-md-end'>
                    <span style={{fontWeight:500}}>Search &nbsp; </span>
                        <Input
                        placeholder="Search Here"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200 }}
                        />
                    </div>                   
                </div>

                {isLoading ? (
                         <div className="text-center">
                              <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                          </div>
                        ) : (
                      <>
                   <Table style={{overflow:"auto"}} columns={columns} 
                    dataSource={filteredData}
                    pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    }
                  }} />

                  </>
                  )}
                </div>

                 {/* Delete Confirmation Modal */}
                <Modal
                    title="Confirm Deletion"
                    visible={deleteModalVisible}
                    onOk={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <p>Are you sure you want to delete this data?</p>
                </Modal>

            </div>


            <Modal className='mb-5'
              title="STUDENT ENTRY FORM"
              top
              open={open}
              footer={null}
              onCancel={cancelModal}
              width={1000}
              maskClosable={false}
            >
              <>

              <div className='row py-2 border'>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>First Name</label>
                                <Input name="fname" value={firstName}   onChange={(e) => setFirstName(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Middle Name</label>
                                <Input name="mname" value={middleName} onChange={(e) => setMiddleName(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Last Name</label>
                                <Input name="lname" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                  <label>Select Class</label> <br/>
                                            <Select className='w-100' showSearch
                                                value={selectClassName}
                                                onChange={(value) => setSelectClassName(value)}
                                                filterOption={(input, option) =>
                                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {classOptions.map((option) => (
                                                    <Option key={option.class_id} value={option.class_id}>
                                                        {option.classname}
                                                    </Option>
                                                ))}
                                            </Select>
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Member ID (Reg No)</label>
                                <Input name="reg_no" value={regNo} onChange={(e) => setRegNo(e.target.value)} />
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Mobile No</label>
                                <Input
                                  name="mob_no"
                                  value={mobileNumber}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value)) {
                                      setMobileNumber(value);
                                    }
                                  }}
                                  maxLength={10} 
                                />
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Email ID</label>
                                <Input name="email_id" type='email' value={emailId}  onChange={handleEmailChange}/>

                                {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                              </div>
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Date of Birth</label>
                                <DatePicker className='w-100' format={dateFormatList}  value={dob}  onChange={(date) => setDob(date)} />
                              </div>                             
                              <div className='col-12 col-md-6 pt-2'>
                                <label>Admission Date</label> 
                                <DatePicker className='w-100' format={dateFormatList}  value={admissionDate}  onChange={(date) => setAdmissionDate(date)} />
                              </div>
                              <div className='col-12 col-md-4 pt-2'>
                                <label>Roll Number</label>
                                <Input name="rollno" type='number' value={rollNumber} onChange={(e) => setRollNumber(e.target.value)} />
                              </div>

                              <div className='col-12 py-4 text-center'>
                                <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
                                {isEditing ? (
                                    <Button className='saveBtn' onClick={handleSaveOrUpdate}>Update</Button>  // Show Update button
                                ) : (
                                    <Button className='saveBtn' onClick={handleSaveOrUpdate}>Save</Button>  // Show Save button
                                )}
                            </div>

                      </div>
                  
              </>
            </Modal>

           


        </div>
    </div>

    <FooterPage/>
</>
  )
}

export default StudentMaster