import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { classMasterDelete, classMasterGet, classMasterSave, classMasterUpdate } from '../../APIs/API';
import { MdEditDocument } from 'react-icons/md';
import { Button, Modal } from 'antd';
import { AiOutlineAlignRight  } from 'react-icons/ai';
import { Table, Input, Space, Pagination } from 'antd';
import { MdDelete } from 'react-icons/md';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import loaderImg from '../../Assets/loader1.gif'

const ClassMaster = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
    //========== Check for session storage and redirect if empty======
    useEffect(() => {
      const token = sessionStorage.getItem('token'); 
  
      if (!token) {
        navigate('/');
      }
    }, [navigate]);
  
  const [data, setData] = useState([]);

  const [className, setClassName] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isEditing, setIsEditing] = useState(false);  // New state to check if editing
const [selectedId, setSelectedId] = useState(null);  // To store selected record ID


      //========== Fetch Table Data =========
      const FetchTableData = ()=>{
        setIsLoading(true);
        axios.get(classMasterGet)
        .then(response => {
            setData(response.data); 
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching class data:', error);
        });
      }

      useEffect(() => {
            FetchTableData();
    }, []);


       // Handle Save button click
       const handleSaveOrUpdate = async () => {
        if (!className) {
            toast.error("Please select all fields before saving");
            return;
        }       
    
        if (isEditing) {

          const payload = {
            class_id: selectedId,
            classname: className,
           };

            try {
                const response = await axios.put(classMasterUpdate, payload);
             
                if (response.data === "UPDATE") {
                    toast.success('Data updated successfully');
                    FetchTableData(); 
                    handleCancel(); 
                }  else if (response.data === 'ALREADYEXIST') {
                  toast.warn('Data already exist!!');
                } else {
                    toast.error('Failed to update data');
                }
            } catch (error) {
                console.error('Error updating data:', error);
                toast.error('An error occurred while updating');
            }
        } else {

          const payload1 = {
            classname: className,
           };

            try {
                const response = await axios.post(classMasterSave, payload1);
                
                if (response.data === "SAVED") {
                    toast.success('Data saved successfully');
                    FetchTableData(); 
                    handleCancel(); 
                } else if (response.data === "ALREADYEXIST") {
                    toast.warn('Data Already Exist');
                } else {
                    toast.error('Failed to save data');
                }
            } catch (error) {
                console.error('Error saving data:', error);
                toast.error('An error occurred while saving');
            }
        }
    };

    // Handle Cancel button click
    const handleCancel = () => {
      setClassName(null);
      setIsEditing(false);  // Reset editing state
      setSelectedId(null);  // Clear selected ID
  };
  
    //==Handle Edit Data= ====
    const handleEditClick = (record) => {
      setClassName(record.classname);  // Populate class name in the input
      setSelectedId(record.class_id);  // Set the ID for updating
      setIsEditing(true);  // Set editing state to true

       // Scroll to the top of the page
       window.scrollTo({top: 0,behavior: 'smooth'});

  };

     // Handle delete button click
     const handleDeleteClick = (record) => {      
      setSelectedRecord(record.class_id); 
      setDeleteModalVisible(true); 
  };
    // Handle delete confirmation
    const handleDeleteConfirm = async () => {
      const classId = {
        class_id : selectedRecord
      }
      
      try {        
          const response = await axios.delete(classMasterDelete, { data: classId }); 
          
          if (response.data === 'DELETE') {
              toast.success('Data Deleted Successfully');
              FetchTableData(); 
              setDeleteModalVisible(false); 
          }else if(response.data === "ERROR"){
              toast.warn('Some error occured!!'); 
          }
          else{
            toast.warn('Some error occured!!'); 
          }
          
      } catch (error) {
          console.error('Error deleting data:', error);
          toast.error('Failed to delete data');
      }
  };

    // Cancel deletion
    const handleDeleteCancel = () => {
        setDeleteModalVisible(false);
    };

  // =====================TABLE CODE=========================

  const columns = [
    {
        title: 'Sr. No.',
        key: 'index',
        render: (_, __, index) => (currentPage - 1) * pageSize + index + 1, 
      },
    {
        title: 'Class Name',
      dataIndex: 'classname',
      key: 'classname',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <>
            <MdEditDocument title='Edit' style={{fontSize:"20px",color:"orange",cursor:"pointer"}}  onClick={() => handleEditClick(record)}/> &nbsp;&nbsp;&nbsp;
            <MdDelete title='Delete' style={{fontSize:"20px",color:"red",cursor:"pointer"}}  onClick={() => handleDeleteClick(record)}/>
          </>
      ),
    },
  ];


      // Filter data based on search input
      const filteredData = data.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
      );

//=========================================================

  return (
    <>
    <HeaderPage/>

    <div className='container-fluid px-lg-5'>

        <div className='row px-3 px-lg-5 mt-2 d-flex align-items-center'>
            <span className='componentTitle'> <AiOutlineAlignRight /> CLASS MASTER</span>
        </div>

        <div className='row m-0 px-lg-5 mb-5'>

            <div className='row m-0 mb-5 shadow px-lg-3 py-4' style={{border:"1px solid #d3d3d3"}}>

                <div className='col-12 col-lg-3 py-2'> </div>
                <div className='col-12 col-lg-6 py-2'>
                    <div className='row p-0 m-lg-0'>
                            <div className='col-2 col-md-4 col-lg-2 p-0 d-flex align-items-center justify-content-center'>
                                    <label className='labelName'>Class :</label>
                            </div>
                            <div className='col-10 col-md-8 col-lg-10 p-0'>
                                    <Input 
                                        style={{ height: "40px" }}
                                        className='w-100'
                                        placeholder="Enter Class"
                                        value={className}
                                        onChange={(e) => setClassName(e.target.value)} 
                                    />
                            </div>
                    </div>       
                </div>
                <div className='col-12 col-lg-3 py-2'> </div>

                <div className='col-12 py-4 text-center'>
                  <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
                  {isEditing ? (
                      <Button className='saveBtn' onClick={handleSaveOrUpdate}>Update</Button>  // Show Update button
                  ) : (
                      <Button className='saveBtn' onClick={handleSaveOrUpdate}>Save</Button>  // Show Save button
                  )}
              </div>


                {/* ======>> table data <<====== */}
                <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

                <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                    <div className='col-12 col-lg-6 text-start text-md-start'>
                    <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                            <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                    </div>
                    <div className='col-12 col-lg-6 text-start text-md-end'>
                    <span style={{fontWeight:500}}>Search &nbsp; </span>
                        <Input
                        placeholder="Search Here"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200 }}
                        />
                    </div>                   
                </div>

                {isLoading ? (
                         <div className="text-center">
                              <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                          </div>
                        ) : (
                      <>

                   <Table  style={{overflow:"auto"}} columns={columns} 
                    dataSource={filteredData}
                    pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    }
                  }}  />
                    </>
                  )}
                </div>

                 {/* Delete Confirmation Modal */}
                <Modal
                    title="Confirm Deletion"
                    visible={deleteModalVisible}
                    onOk={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <p>Are you sure you want to delete this data?</p>
                </Modal>

            </div>


        </div>
    </div>

    <FooterPage/>
</>
  )
}

export default ClassMaster