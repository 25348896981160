import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { Button, Modal, Select } from 'antd';
import '../../App.css'
import loaderImg from '../../Assets/loader1.gif'
import { AiOutlineAlignRight  } from 'react-icons/ai';
import { Table, Input, Space, Pagination } from 'antd';
import { MdDelete } from 'react-icons/md';
import axios from 'axios';
import { Option } from 'antd/es/mentions';
import { toast } from 'react-toastify';
import { classMasterGet, semesterGet, subjectAllotmentDelete, subjectAllotmentGet, subjectAllotmentSave, subjectGet } from '../../APIs/API';
import { useNavigate } from 'react-router-dom';

const SubjectAllotment = () => {

    const navigate = useNavigate();
    //========== Check for session storage and redirect if empty======
    useEffect(() => {
      const token = sessionStorage.getItem('token'); 
  
      if (!token) {
        navigate('/');
      }
    }, [navigate]);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [classes, setClasses] = useState([]);  // Class data
    const [semesters, setSemesters] = useState([]);  // Semester data
    const [subjects, setSubjects] = useState([]);  // Subject data

    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSemester, setSelectedSemester] = useState(null);
    const [selectedSubject, setSelectedSubject] = useState(null);

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

//========== Fetch Classes =========
      const ClassData = ()=>{
        axios.get(classMasterGet)
        .then(response => {
            setClasses(response.data); 
        })
        .catch(error => {
            console.error('Error fetching class data:', error);
        });
      }
//========== Fetch Subjects =========
      const SubjectData=()=>{
            axios.get(subjectGet)
            .then(response => {
                setSubjects(response.data);
            })
            .catch(error => {
                console.error('Error fetching subject data:', error);
            });
      }
//========== Fetch Semesters =========
      const SemesterData=()=>{
            axios.get(semesterGet)
            .then(response => {
                setSemesters(response.data); 
            })
            .catch(error => {
                console.error('Error fetching semester data:', error);
            });
      }

       //========== Fetch Table Data =========
    const FetchTableData = ()=>{
        setIsLoading(true);
        axios.get(subjectAllotmentGet)
        .then(response => {
            setData(response.data); 
            setIsLoading(false);
        })
        .catch(error => {
            console.error('Error fetching class data:', error);
        });
      }

      useEffect(() => {
            ClassData();
            SubjectData();
            SemesterData();
            FetchTableData();
    }, []);


    // Handle Save button click
    const handleSave = async () => {
        if (!selectedClass || !selectedSemester || !selectedSubject) {
            toast.error("Please select all fields before saving");
            return;
        }

        const payload = {
            class_id: selectedClass,
            semister_id: selectedSemester,
            sub_id: selectedSubject
        };

        console.log("=====>>>payload",payload);

        try {
            const response = await axios.post(subjectAllotmentSave, payload);

            console.log("=====>>>",response);
            
            if (response.data === "SAVED") {
                toast.success('Data saved successfully');
                FetchTableData(); 
                handleCancel(); 
            }
            else if (response.data === "ALREADYEXIST") {
                toast.warn('Data Already Exist'); 
            } else {
                toast.error('Failed to save data');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            toast.error('An error occurred');
        }
    };

    // Handle Cancel button click
    const handleCancel = () => {
        setSelectedClass(null);
        setSelectedSemester(null);
        setSelectedSubject(null);
    };

     // Handle delete button click
     const handleDeleteClick = (record) => {
        setSelectedRecord(record); 
        setDeleteModalVisible(true); 
    };
      // Handle delete confirmation
      const handleDeleteConfirm = async () => {
        try {
            const response = await axios.delete(`${subjectAllotmentDelete}/${selectedRecord.id}`); 
            console.log(response);
            if (response.data === 'DELETE') {
                toast.success('Data Deleted Successfully');
                FetchTableData(); 
                setDeleteModalVisible(false); 
            }else{
                toast.warn('Some error occured!!'); 
            }
            
        } catch (error) {
            console.error('Error deleting data:', error);
            toast.error('Failed to delete data');
        }
    };

    // Cancel deletion
    const handleDeleteCancel = () => {
        setDeleteModalVisible(false);
    };

// =====================TABLE CODE=========================

      const columns = [
        {
            title: 'Sr. No.',
            key: 'index',
             render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
          },
        {
            title: 'Class',
          dataIndex: 'classname',
          key: 'classname',
        },
        {
          title: 'Semester',
          dataIndex: 'semister_name',
          key: 'semister_name',
        },
        {
          title: 'Subject',
          dataIndex: 'subjectname',
          key: 'subjectname',
        },
        {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
              <MdDelete style={{fontSize:"20px",color:"red",cursor:"pointer"}}  onClick={() => handleDeleteClick(record)}/>
          ),
        },
      ];
   

// Filter data based on search input
  const filteredData = data.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );
//=========================================================

  return (
    <>
    <HeaderPage/>

    <div className='container-fluid px-lg-5'>

        <div className='row px-3 px-lg-5 mt-2 d-flex align-items-center'>
            <span className='componentTitle'> <AiOutlineAlignRight /> SUBJECT ALLOTMENT</span>
        </div>

        <div className='row m-0 px-lg-5 mb-5'>

            <div className='row m-0 mb-5 shadow px-lg-3 py-4' style={{border:"1px solid #d3d3d3"}}>

                <div className='col-12 col-lg-4 py-2'>
                    <div className='row p-0 m-lg-0'>
                            <div className='col-4 p-0 d-flex align-items-center'>
                                    <label className='labelName'>Select Class :</label>
                            </div>
                            <div className='col-8 p-0'>
                                    <Select
                                        style={{ height: "40px" }}
                                        showSearch
                                        className='w-100'
                                        placeholder="Select Class"
                                        optionFilterProp="label"
                                        value={selectedClass}
                                        onChange={value => setSelectedClass(value)}
                                        filterOption={(input, option) =>
                                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                    >
                                        {classes.map(classItem => (
                                            <Option key={classItem.class_id} value={classItem.class_id}>
                                                {classItem.classname}
                                            </Option>
                                        ))}
                                    </Select>
                            </div>
                    </div>       
                </div>
                <div className='col-12 col-lg-4 py-2'>
                    <div className='row p-0 m-lg-0'>
                            <div className='col-4 p-0 d-flex align-items-center'>
                                    <label className='labelName'>Select Semester :</label>
                            </div>
                            <div className='col-8 p-0'>
                                    <Select
                                        style={{ height: "40px" }}
                                        showSearch
                                        className='w-100'
                                        placeholder="Select Semester"
                                        optionFilterProp="label"
                                        value={selectedSemester}
                                        onChange={value => setSelectedSemester(value)}
                                        filterOption={(input, option) => {
                                            if (!option || !option.children) return false; // Add null check here
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                    >
                                        {semesters.map(semester => (
                                            <Option key={semester.semister_id} value={semester.semister_id}>
                                                {semester.semister_name}
                                            </Option>
                                        ))}
                                    </Select>
                            </div>
                    </div>       
                </div>
                <div className='col-12 col-lg-4 py-2'>
                    <div className='row p-0 m-lg-0'>
                            <div className='col-4 p-0 d-flex align-items-center'>
                                    <label className='labelName'>Select Subject :</label>
                            </div>
                            <div className='col-8 p-0'>
                                    <Select
                                        style={{ height: "40px" }}
                                        showSearch
                                        className='w-100'
                                        placeholder="Select Subject"
                                        optionFilterProp="label"
                                        value={selectedSubject}
                                        onChange={value => setSelectedSubject(value)}
                                        filterOption={(input, option) =>
                                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                    >
                                        {subjects.map(subject => (
                                            <Option key={subject.sub_id} value={subject.sub_id}>
                                                {subject.subjectname}
                                            </Option>
                                        ))}
                                    </Select>
                            </div>
                    </div>       
                </div>

                <div className='col-12 py-4 text-center'>
                    <Button  className='cancelBtn'  onClick={handleCancel}>Cancel</Button>
                    <Button  className='saveBtn' onClick={handleSave}>Save</Button>
                </div>

                {/* ======>> table data <<====== */}

                {isLoading ? (
                    <div className="text-center">
                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                    </div>
                 ) : (
                <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

                <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                    <div className='col-12 col-lg-6 text-start text-md-start'>
                    <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                            <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                    </div>
                    <div className='col-12 col-lg-6 text-start text-md-end'>
                    <span style={{fontWeight:500}}>Search &nbsp; </span>
                        <Input
                        placeholder="Search Here"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        style={{ width: 200 }}
                        />
                    </div>                   
                </div>

                   <Table style={{overflow:"auto"}} columns={columns} 
                    dataSource={filteredData}
                    pagination={{
                    current: currentPage,
                    pageSize: pageSize,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    }
                    }}
                   
                    />
                </div>
                )}

                 {/* Delete Confirmation Modal */}
                <Modal
                    title="Confirm Deletion"
                    visible={deleteModalVisible}
                    onOk={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                    okText="Delete"
                    cancelText="Cancel"
                >
                    <p>Are you sure you want to delete this data?</p>
                </Modal>

            </div>


        </div>
    </div>

    <FooterPage/>
</>
  )
}

export default SubjectAllotment