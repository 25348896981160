import React, { useEffect, useState } from 'react' 
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import './DashboardCSS.css'
import { FaUserGraduate, FaUsers } from 'react-icons/fa'
import axios from 'axios'; // Import axios for API calls
import { MdDelete, MdOutlinePendingActions } from 'react-icons/md'
import { Input, Table } from 'antd'
import loader from '../../Assets/loader.gif'
import { LuFileCheck2 } from 'react-icons/lu'
import { useNavigate } from 'react-router-dom'
import { dashboardCountGet, todaysAttendanceDataGet, totalAttendanceDataGet } from '../../APIs/API'

const Dashboard = () => {
  
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoading1, setIsLoading1] = useState(true);
  
   //========== Check for session storage and redirect if empty======
   useEffect(() => {
    const token = sessionStorage.getItem('token'); 

    if (!token) {
      navigate('/');
    }
  }, [navigate]);


  //--------------------Disabled back button---------------------------
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.forward();
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [ ]);

  //================================================================

  // ======four block code==========
  // State to hold the API data
  const [dashboardData, setDashboardData] = useState({
    totalEmployees: 0,
    totalStudents: 0,
    registeredStudents: 0,
    pendingStudent: 0,
    approvalStudent: 0
  });

  // Fetch data from the GET API
  useEffect(() => {
    axios.get(dashboardCountGet) 
      .then(response => {
        const data = response.data;
        setDashboardData({
          totalEmployees: data.totalEmployee || 0, 
          totalStudents: data.studentMaster || 0,
          registeredStudents: data.registerStudent || 0,
          pendingStudent: data.pendingStudent || 0,
          approvalStudent: data.approvalStudent || 0
        });
      })
      .catch(error => {
        console.error('Error fetching the data', error);
      });
  }, []);

  // =====================TABLE CODE=========================
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);

     //========== Fetch Total Attendance Table Data =========
     const FetchTableData = ()=>{
      axios.get(totalAttendanceDataGet)
      .then(response => {
          setData(response.data); 
          setIsLoading(false);
      })
      .catch(error => {
          console.error('Error fetching class data:', error);
          setIsLoading(false);
      });
    }

    useEffect(() => {
      FetchTableData();
     }, []);

  const columns = [
    {
        title: 'Sr. No.',
        key: 'index',
        render: (_, __, index) => index + 1, 
      },
    {
        title: 'Class',
      dataIndex: 'classname',
      key: 'classname',
    },
    {
      title: 'Subject',
      dataIndex: 'subjectname',
      key: 'subjectname',
    },
    {
      title: 'Lecture Count',
      dataIndex: 'lecture',
      key: 'lecture',
    },
    {
      title: 'Present Students',
      dataIndex: 'studentPresent',
      key: 'studentPresent',
    },
    {
      title: 'Percentage (%)',
      dataIndex: 'percent',
      key: 'percent',
    },
  ];


  // Filter data based on search input
  const filteredData = data.filter(item =>
  Object.values(item).some(value =>
    value.toString().toLowerCase().includes(searchText.toLowerCase())
  )
  );

  // =====================TABLE-1 CODE=========================
  const [data1, setData1] = useState([]);
  const [searchText1, setSearchText1] = useState('');
  const [pageSize1, setPageSize1] = useState(10);

     //========== Fetch Todays Attendance Table Data1 =========
     const FetchTableData1 = ()=>{
      axios.get(todaysAttendanceDataGet)
      .then(response => {
          setData1(response.data); 
          setIsLoading1(false);
      })
      .catch(error => {
          console.error('Error fetching class data:', error);
          setIsLoading1(false);
      });
    }

    useEffect(() => {
      FetchTableData1();
     }, []);

     const columns1 = [
      {
          title: 'Sr. No.',
          key: 'index',
          render: (_, __, index) => index + 1, 
        },
      {
          title: 'Class',
        dataIndex: 'classname',
        key: 'classname',
      },
      {
        title: 'Subject',
        dataIndex: 'subjectname',
        key: 'subjectname',
      },
      {
        title: 'Teacher Name',
        dataIndex: 'teacher_name',
        key: 'teacher_name',
      },
      {
        title: 'Lecture',
        dataIndex: 'lecture',
        key: 'lecture',
      },
      {
        title: 'Present Students',
        dataIndex: 'studentPresent',
        key: 'studentPresent',
      },
    ];


  // Filter data based on search input
  const filteredData1 = data1.filter(item =>
  Object.values(item).some(value =>
    value.toString().toLowerCase().includes(searchText1.toLowerCase())
  )
  );


  return (
    <>
      <HeaderPage />

      <div className='container-fluid'>

        <div className='row py-3 px-1 px-md-3'>
          <div className='col-12 col-md-6 col-lg-3 p-2 py-3'>
            <div className='rounded shadow block1 p-2'> 
              <div className='row p-0 m-0 rounded border h-100'>
                <div className='col-5 d-flex justify-content-center align-items-center'>
                  <FaUsers style={{fontSize:"45px"}} />
                </div>
                <div className='col-7 d-flex justify-content-center align-items-center flex-column'>
                  <h4 className='text-center'>{dashboardData.totalEmployees}</h4>
                  <span className='text-center'>Total Employees</span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-3 p-2 py-3'>
            <div className='rounded shadow block2 p-2'>
              <div className='row p-0 m-0 rounded border h-100'>
                <div className='col-2 d-flex justify-content-center align-items-center'>
                  <FaUserGraduate style={{fontSize:"40px"}} />
                </div>
                <div className='col-4 d-flex justify-content-center align-items-center flex-column'>
                  <h4>{dashboardData.totalStudents}</h4>
                  <span className='text-center'>Total Students</span>
                </div>
                <div className='col-6 p-1 d-flex justify-content-center align-items-center flex-column'>
                  <div className='rounded text-center' style={{ backgroundColor: "rgba(255, 255, 255, 0.5)",color:"#000" }}>
                    <h4>{dashboardData.registeredStudents}</h4>
                    <span>Total Registered Students</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-3 p-2 py-3'>
            <div className='rounded shadow block3 p-2'>
              <div className='row p-0 m-0 rounded border h-100'>
                <div className='col-5 d-flex justify-content-center align-items-center'>
                  <MdOutlinePendingActions style={{fontSize:"45px"}} />
                </div>
                <div className='col-7 d-flex justify-content-center align-items-center flex-column'>
                  <h4 className='text-center'>{dashboardData.pendingStudent}</h4>
                  <span className='text-center'>Approval Pending Students</span>
                </div>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6 col-lg-3 p-2 py-3'>
            <div className='rounded shadow block4 p-2'>
              <div className='row p-0 m-0 rounded border h-100'>
                <div className='col-5 d-flex justify-content-center align-items-center'>
                  <LuFileCheck2 style={{fontSize:"45px"}} />
                </div>
                <div className='col-7 d-flex justify-content-center align-items-center flex-column'>
                  <h4 className='text-center'>{dashboardData.approvalStudent}</h4>
                  <span className='text-center'>Approved Students</span>
                </div>
              </div>
            </div>
          </div>

        </div>
        
        <div className='row pb-5 mb-3 px-1 px-md-3 mt-2'>

              <div className='col-12 col-lg-6'>

                  <div className='row p-0 m-0 py-1 border d-flex align-items-center' style={{backgroundColor:"#0595e7",color:"#fff",fontSize:"20px"}}>
                      <span>Total Attendance Data</span>
                  </div>

                  <div className='row py-1  m-0 border d-flex align-items-center' style={{backgroundColor:"#fff",color:"#000",fontSize:"20px"}}>

                      <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                        <div className='col-12 col-lg-6 text-start text-md-start'>
                        <span style={{fontWeight:500,fontSize:"14px"}}>Show entries &nbsp; </span>
                                <select style={{fontSize:"16px"}} onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                        </div>
                        <div className='col-12 col-lg-6 text-start text-md-end'>
                        <span style={{fontWeight:500,fontSize:"14px"}}>Search &nbsp; </span>
                            <Input
                            placeholder="Search Here"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: 200 }}
                            />
                        </div>                   
                      </div>
                      {isLoading ? <div className='text-center'><img src={loader} /></div> : (
                      <Table style={{height:"260px",overflow:"auto"}} columns={columns} 
                        dataSource={filteredData}
                        pagination={{ pageSize }}
                       
                        />
                        )}
                  </div>

              </div>

              <div className='col-12 col-lg-6'>

                  <div className='row p-0 m-0 py-1 border d-flex align-items-center' style={{backgroundColor:"#0595e7",color:"#fff",fontSize:"20px"}}>
                      <span>Todays Attendance Data</span>
                  </div>

                  <div className='row py-1 px-2 m-0 border d-flex align-items-center' style={{backgroundColor:"#fff",color:"#000",fontSize:"20px"}}>
                  <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                        <div className='col-12 col-lg-6 text-start text-md-start'>
                        <span style={{fontWeight:500,fontSize:"14px"}}>Show entries &nbsp; </span>
                                <select style={{fontSize:"16px"}} onChange={(e) => setPageSize1(Number(e.target.value))} value={pageSize1}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                        </div>
                        <div className='col-12 col-lg-6 text-start text-md-end'>
                        <span style={{fontWeight:500,fontSize:"14px"}}>Search &nbsp; </span>
                            <Input
                            placeholder="Search Here"
                            value={searchText1}
                            onChange={(e) => setSearchText1(e.target.value)}
                            style={{ width: 200 }}
                            />
                        </div>                   
                      </div>
                      {isLoading1 ? <div className='text-center'><img src={loader} /></div> : (
                      <Table style={{height:"260px",overflow:"auto"}} columns={columns1} 
                        dataSource={filteredData1}
                        pagination={{ pageSize }}
                        
                        />
                      )}
                  </div>

              </div>
        </div>

      </div>

      <FooterPage />
    </>
  )
}

export default Dashboard;
