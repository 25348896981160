import React, { useEffect, useState } from "react";
import HeaderPage from "../CommonComponents/HeaderPage";
import FooterPage from "../CommonComponents/FooterPage";
import { AiOutlineAlignRight } from "react-icons/ai";
import {
  AttendanceSummeryDataGet,
  classMasterGet,
  subjectMasterGet,
} from "../../APIs/API";
import { Button, Input, Select, Table, DatePicker, Checkbox } from "antd";
import { Option } from "antd/es/mentions";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import loaderImg from '../../Assets/loader1.gif'
import { useNavigate } from "react-router-dom";

const AttendanceSummaryReport = () => {
  const navigate = useNavigate();
  //========== Check for session storage and redirect if empty======
  useEffect(() => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const dateFormatList = ["DD/MM/YYYY"];

  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  //==============================================================
  const [isLoading, setIsLoading] = useState(false);
  const [classOptions, setClassOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);

  const [selectClassName, setSelectClassName] = useState(null);
  const [selectSubjectName, setSelectSubjectName] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAllSubjectsChecked, setIsAllSubjectsChecked] = useState(false);

  //========== Fetch Table Data =========
  const handleSave = () => {
    setIsLoading(true);
    if (
      !selectClassName ||
      !fromDate ||
      !toDate ||
      (!selectSubjectName && !isAllSubjectsChecked)
    ) {
      toast.error("Please select all fields before saving");
      return;
    }

    // Format dates to dd/mm/yyyy
    const formattedFromDate = fromDate ? formatDate(new Date(fromDate)) : null;
    const formattedToDate = toDate ? formatDate(new Date(toDate)) : null;

    let flag = 1; // Default flag when checkbox is not selected
    let subjectId = selectSubjectName;

    if (isAllSubjectsChecked) {
      flag = 2; // Set flag to 1 when "All Subjects" checkbox is selected
      subjectId = 0; // Set subject ID to 0 when checkbox is selected
    }

    axios
      .get(
        `${AttendanceSummeryDataGet}/${selectClassName}/${flag}/${subjectId}/${formattedFromDate}/${formattedToDate}`
      )
      .then((response) => {
        
        setData(response.data);
        setData1(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching subject lecture data:", error);
      });
  };

  // Fetch Class Options
  const FetchClassOptions = () => {
    axios
      .get(classMasterGet)
      .then((response) => {
        setClassOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching class options:", error);
      });
  };

  // Fetch Subject Options
  const FetchSubjectOptions = () => {
    axios
      .get(subjectMasterGet)
      .then((response) => {
        setSubjectOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching subject options:", error);
      });
  };

  useEffect(() => {
    FetchClassOptions();
    FetchSubjectOptions();
  }, []);

  // Handle Cancel button click
  const handleCancel = () => {
    setSelectClassName(null);
    setSelectSubjectName(null);
    setFromDate(null);
    setToDate(null);
    setIsAllSubjectsChecked(false);
    setData([]);
  };

  // ==================== Export to Excel ====================
  // const handleDownloadExcel = () => {
  //   if (data) {
  //     toast.error("No data available to download");
  //     return;
  //   }

  //   const excelData = data.map((row, index) => ({
  //     "Sr. No.": index + 1,
  //     "Class Name": row.classname,
  //     "Subject Name": row.subjectname,
  //     "Student Name": row.studentname,
  //     "Reg No": row.reg_no,
  //     "Lecture Count": row.lectCount,
  //     "Attended Count": row.studentCount,
  //     "Attendance (%)": row.attendancePercentage,
  //     Remark: "", // Remark (blank data)
  //   }));

  //   const worksheet = XLSX.utils.json_to_sheet(excelData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(
  //     workbook,
  //     worksheet,
  //     "Attendance Summary Report"
  //   );

  //   XLSX.writeFile(workbook, "Attendance_Summary_Report.xlsx");
  // };

  const handleDownloadExcel = () => {
    if (!data || !data.subjects) {
      toast.error("No data available to download");
      return;
    }
  
    // Create a new workbook
    const wb = XLSX.utils.book_new();
  
    // Iterate through each subject
    data.subjects.forEach((subject, subjectIndex) => {
      if (!subject.classname || !subject.subjectname) {
        console.error("Invalid subject data:", subject);
        return; // Skip if classname or subjectname is missing
      }
  
      // Add the class and subject information
      const classAndSubjectInfo = [
        [`Class Name: ${subject.classname}`, `Subject Name: ${subject.subjectname}`]
      ];
  
      // Convert students data into rows for Excel
      const tableHeader = [
        // "Sr No",
        "Roll No",
        "Student Name",
        "Reg No",
        "Lecture Count",
        "Attended Count",
        "Attendance (%)",
        "Remark"
      ];
  
      const tableRows = subject.students.map((student, index) => [
        // index + 1, // Sr No
        student.roll_no || "", 
        student.studentname || "", // Student Name
        student.reg_no || "", // Reg No
        student.lectCount !== undefined ? student.lectCount : 0, // Lecture Count
        student.studentCount !== undefined ? student.studentCount : 0, // Attended Count
        student.attendancePercentage !== undefined
          ? student.attendancePercentage.toString()
          : "0.00", // Attendance (%)
        "" // Remark (can leave blank)
      ]);
  
      // Combine everything into one worksheet data
      const sheetData = [
        ...classAndSubjectInfo,
        tableHeader,
        ...tableRows,
        [] // Empty row for spacing between subjects
      ];
  
      // Add the worksheet for this subject
      const ws = XLSX.utils.aoa_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(wb, ws, `Subject ${subjectIndex + 1}`);
    });
  
    // Write the Excel file and trigger download
    XLSX.writeFile(wb, "Attendance_Summary_Report.xlsx");
  };


  // ==================== Export to PDF ====================
  // const handleDownloadPdf = () => {
  //   if (data) {
  //     toast.error("No data available to download");
  //     return;
  //   }

  //   const doc = new jsPDF();

  //    // Get page width and calculate the center position for the college name
  //    const pageWidth = doc.internal.pageSize.getWidth();
  //    const pageHeight = doc.internal.pageSize.getHeight();

  //       const collegeName = "Latthe Education Society's";
  //       const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
  //       const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

  //     const fromDate = data1.fromdate;
  //     const toDate = data1.todate;

  //       doc.setFontSize(10);
  //       const textWidth1 = doc.getTextWidth(collegeName);
  //       const centerX1 = (pageWidth - textWidth1) / 2;
  //       doc.text(collegeName, centerX1, 10);

  //       doc.setFontSize(17);
  //       const textWidth2 = doc.getTextWidth(collegeName1);
  //       const centerX2 = (pageWidth - textWidth2) / 2;
  //       doc.text(collegeName1, centerX2, 16);

  //       doc.setFontSize(7);
  //       const textWidth3 = doc.getTextWidth(collegeName2);
  //       const centerX3 = (pageWidth - textWidth3) / 2;
  //       doc.text(collegeName2, centerX3, 20);

  //       // Set font size for the teacher and date
  //       doc.setFontSize(10);

  //        const dateRangeText = `From: ${fromDate} - To: ${toDate}`;
  //        const textWidthDate = doc.getTextWidth(dateRangeText);
  //        const xPositionDate = pageWidth - textWidthDate - 15;

  //        doc.text(dateRangeText, xPositionDate, 27);

  //   // Add a border function that runs for every page
  //   const addBorder = () => {
  //     doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
  //   };

  //   // Convert the data to rows for jsPDF autotable
  //   const tableColumn = ['Sr No','Class Name', 'Subject Name','Student Name','Reg No', 'Lecture Count', 'Attended Count','Attendance (%)',"Remark"];
  //   const tableRows = data.map((row,index )=> [
  //     index + 1,
  //     row.classname,
  //     row.subjectname,
  //     row.studentname,
  //     row.reg_no,
  //     row.lectCount,
  //     row.studentCount,
  //     row.attendancePercentage,
  //     " "
  //   ]);

  //   // Add the table to PDF
  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 30, // Adjust to position the table below the title
  //     styles: {
  //     lineWidth: 0.1, // Border thickness for table cells
  //     lineColor: [0, 0, 0], // Black border color
  //   },
  //   tableLineColor: [0, 0, 0], // Border color for table's outer frame
  //   tableLineWidth: 0.1, // Border thickness for the table's outer frame
  //     didDrawPage: (data) => {
  //       addBorder(); // Draw the border on every new page
  //     },
  //   });

  //   // Save the PDF
  //   doc.save('Attendance_Summary_Report.pdf');
  // };

  const handleDownloadPdf = () => {
    if (!data || !data.subjects) {
      toast.error("No data available to download");
      return;
    }

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    const collegeName = "Latthe Education Society's";
    const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
    const collegeName2 =
      "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";
    const fromDate = data.fromdate;
    const toDate = data.todate;

    // Add college names to PDF
    try {
      // College name
      doc.setFontSize(10);
      const centerX1 = (pageWidth - doc.getTextWidth(collegeName)) / 2;
      doc.text(collegeName, centerX1, 10);

      // College name 1
      doc.setFontSize(17);
      const centerX2 = (pageWidth - doc.getTextWidth(collegeName1)) / 2;
      doc.text(collegeName1, centerX2, 16);

      // College name 2
      doc.setFontSize(7);
      const centerX3 = (pageWidth - doc.getTextWidth(collegeName2)) / 2;
      doc.text(collegeName2, centerX3, 20);

      // Date range
      doc.setFontSize(10);
      const dateRangeText = `From: ${fromDate} - To: ${toDate}`;
      const xPositionDate = pageWidth - doc.getTextWidth(dateRangeText) - 15;
      doc.text(dateRangeText, xPositionDate, 27);
    } catch (error) {
      console.error("Error adding header text:", error);
      return; // Exit the function if there’s an error in adding header
    }

    // Initialize startY
    let startY = 35; // Start below the header text; can be adjusted as needed

    // Iterate through each subject
    data.subjects.forEach((subject, subjectIndex) => {
      if (!subject.classname || !subject.subjectname) {
        console.error("Invalid subject data:", subject);
        return; // Skip if classname or subjectname is missing
      }

      try {

         // College name
      doc.setFontSize(10);
      const centerX1 = (pageWidth - doc.getTextWidth(collegeName)) / 2;
      doc.text(collegeName, centerX1, 10);

      // College name 1
      doc.setFontSize(17);
      const centerX2 = (pageWidth - doc.getTextWidth(collegeName1)) / 2;
      doc.text(collegeName1, centerX2, 16);

      // College name 2
      doc.setFontSize(7);
      const centerX3 = (pageWidth - doc.getTextWidth(collegeName2)) / 2;
      doc.text(collegeName2, centerX3, 20);

      // Date range
      doc.setFontSize(10);
      const dateRangeText = `From: ${fromDate} - To: ${toDate}`;
      const xPositionDate = pageWidth - doc.getTextWidth(dateRangeText) - 15;
      doc.text(dateRangeText, xPositionDate, 27);

      //===========================================================

        // Class heading
        doc.setFontSize(10);
        const classNameText = `Class Name: ${subject.classname}`;
        const xPositionClassName = 15;
        doc.text(classNameText, xPositionClassName, 32);

        // Subject heading
        doc.setFontSize(10);
        const subjectNameText = `Subject Name: ${subject.subjectname}`;
        const xPositionSubjectName = pageWidth - doc.getTextWidth(subjectNameText) -15;
        doc.text(subjectNameText, xPositionSubjectName, 32);

        // Convert students data to rows for jsPDF autotable
        const tableColumn = [
          // "Sr No",
          "Roll No",
          "Student Name",
          "Reg No",
          "Lecture Count",
          "Attended Count",
          "Attendance (%)",
          "Remark",
        ];
        const tableRows = subject.students.map((student, index) => {
          return [
            // index + 1,
            student.roll_no || "", 
            student.studentname || "", 
            student.reg_no || "", 
            student.lectCount !== undefined ? student.lectCount : 0, 
            student.studentCount !== undefined ? student.studentCount : 0, 
            student.attendancePercentage !== undefined
              ? student.attendancePercentage.toString()
              : "0.00", // Ensure it's a string
            " ", // Placeholder for Remark
          ];
        });

        // Add the table to PDF
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: 36, // Position table below the headings
          styles: {
            lineWidth: 0.1, // Border thickness for table cells
            lineColor: [0, 0, 0], // Black border color
          },
          tableLineColor: [0, 0, 0], // Border color for table's outer frame
          tableLineWidth: 0.1, // Border thickness for the table's outer frame
          didDrawPage: (data) => {
            doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // Draw the border on every new page

               // Add page number at the bottom of each page
          doc.setFontSize(8);
          doc.text(`Page No. ${data.pageNumber}`, pageWidth - 30, pageHeight - 10);

          },
        });

        // Update startY for the next subject
        startY = doc.autoTable.previous.finalY + 35; // 20 units below the last table

        // Add a page break after each subject if it's not the last subject
        if (subjectIndex < data.subjects.length - 1) {
          doc.addPage();
          startY = 10; 
        }
      } catch (error) {
        console.error("Error processing subject:", subject, error);
      }
    });

    // Save the PDF
    doc.save("Attendance_Summary_Report.pdf");
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setIsAllSubjectsChecked(e.target.checked);
    if (e.target.checked) {
      setSelectSubjectName(null); // Clear selected subject when checkbox is checked
    }
  };

  // =====================TABLE CODE=========================

  // const columns = [
  //   {
  //     title: "Sr. No.",
  //     key: "index",
  //     render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
  //   },
  //   {
  //     title: "Class Name",
  //     dataIndex: "classname",
  //     key: "classname",
  //   },
  //   {
  //     title: "Subject Name",
  //     dataIndex: "subjectname",
  //     key: "subjectname",
  //   },
  //   {
  //     title: "Reg No",
  //     dataIndex: "reg_no",
  //     key: "reg_no",
  //   },
  //   {
  //     title: "Student Name",
  //     dataIndex: "studentname",
  //     key: "studentname",
  //   },
  //   {
  //     title: "Lecture Count",
  //     dataIndex: "lectCount",
  //     key: "lectCount",
  //   },
  //   {
  //     title: "Attended Count",
  //     dataIndex: "studentCount",
  //     key: "studentCount",
  //   },
  //   {
  //     title: "Attendance Percentage",
  //     dataIndex: "attendancePercentage",
  //     key: "attendancePercentage",
  //   },
  // ];

  // Filter data based on search input
  // const filteredData = data.filter(item =>
  // Object.values(item).some(value =>
  //   value.toString().toLowerCase().includes(searchText.toLowerCase())
  // )
  // );

  // const filteredData = Array.isArray(data)
  //   ? data.filter((item) =>
  //       Object.values(item).some((value) =>
  //         value.toString().toLowerCase().includes(searchText.toLowerCase())
  //       )
  //     )
  //   : [];

  //===============================================================================================

  //   return (
  //     <>
  //     <HeaderPage/>

  //     <div className='container-fluid'>

  //         <div className='row px-1 px-lg-5 mt-2 d-flex align-items-center'>
  //             <span className='componentTitle'> <AiOutlineAlignRight /> Attendance Summary Report</span>
  //         </div>

  //         <div className='row m-0 px-lg-5 mb-5'>

  //             <div className='row m-0 mb-5 shadow px-lg-3 py-4' style={{border:"1px solid #d3d3d3"}}>

  //                       <div className='row m-0 py-2'>
  //                         <div className='col-12 col-md-2 py-2'></div>
  //                         <div className='col-12 col-md-8 py-2'>
  //                             <div className='row py-2'>
  //                                 <div className='col-12 col-md-6 pt-2'>
  //                                       <label>Select Class</label> <br/>
  //                                                 <Select className='w-100' showSearch
  //                                                     value={selectClassName} placeholder="Select Class"
  //                                                     onChange={(value) => setSelectClassName(value)}
  //                                                     filterOption={(input, option) =>
  //                                                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //                                                     }
  //                                                 >
  //                                                     {classOptions.map((option) => (
  //                                                         <Option key={option.class_id} value={option.class_id}>
  //                                                             {option.classname}
  //                                                         </Option>
  //                                                     ))}
  //                                                 </Select>
  //                                   </div>
  //                                   <div className='col-12 col-md-6 pt-2'>
  //                                       <label>Select Subject</label> <br/>
  //                                       <div className='row' style={{height:"34px"}}>
  //                                             <div className='col-12 col-md-2 d-flex align-items-center'>
  //                                                 <Checkbox checked={isAllSubjectsChecked} onChange={handleCheckboxChange}>All</Checkbox>
  //                                             </div>
  //                                             {!isAllSubjectsChecked && (
  //                                             <div className='col-12 col-md-10'>
  //                                                 <Select  showSearch className='w-100'
  //                                                     value={selectSubjectName} placeholder="Select Subject"
  //                                                     onChange={(value) => setSelectSubjectName(value)}
  //                                                     filterOption={(input, option) =>
  //                                                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  //                                                     }
  //                                                 >
  //                                                     {subjectOptions.map((option) => (
  //                                                         <Option key={option.sub_id} value={option.sub_id}>
  //                                                             {option.subjectname}
  //                                                         </Option>
  //                                                     ))}
  //                                                 </Select>
  //                                             </div>
  //                                             )}
  //                                       </div>

  //                                   </div>
  //                                   <div className='col-12 col-md-6 pt-4 pt-md-2'>
  //                                       <label>From Date</label> <br/>
  //                                       <DatePicker className='w-100' format={dateFormatList}  value={fromDate}  onChange={(date) => setFromDate(date)} />
  //                                   </div>
  //                                   <div className='col-12 col-md-6 pt-2'>
  //                                       <label>To Date :</label> <br/>
  //                                       <DatePicker className='w-100'  format={dateFormatList}  value={toDate}  onChange={(date) => setToDate(date)} />
  //                                   </div>

  //                                   <div className='col-12 py-4 text-center'>
  //                                     <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
  //                                     <Button className='saveBtn' onClick={handleSave}>Show Data</Button>
  //                                   </div>
  //                             </div>
  //                         </div>
  //                         <div className='col-12 col-md-2 py-2'></div>
  //                       </div>

  //                           {/* ======>> table data <<====== */}
  //                         <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

  //                         {data ? (
  //                           <>
  //                               <div className='col-12 py-4 text-center text-md-end'>
  //                                 <Button className='downloadBtn' onClick={handleDownloadExcel}> Download Excel</Button>
  //                                 <Button className='downloadBtn' onClick={handleDownloadPdf}>Download Pdf</Button>
  //                               </div>

  //                               <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
  //                                   <div className='col-12 col-lg-6 text-start text-md-start'>
  //                                   <span style={{fontWeight:500}}>Show entries &nbsp; </span>
  //                                           <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
  //                                               <option value={10}>10</option>
  //                                               <option value={50}>50</option>
  //                                               <option value={100}>100</option>
  //                                           </select>
  //                                   </div>
  //                                   <div className='col-12 col-lg-6 text-start text-md-end'>
  //                                   <span style={{fontWeight:500}}>Search &nbsp; </span>
  //                                       <Input
  //                                       placeholder="Search Here"
  //                                       value={searchText}
  //                                       onChange={(e) => setSearchText(e.target.value)}
  //                                       style={{ width: 200 }}
  //                                       />
  //                                   </div>
  //                               </div>

  //                                 <Table style={{overflow:"auto"}} columns={columns}
  //                                   dataSource={filteredData}
  //                                   pagination={{
  //                                   current: currentPage,
  //                                   pageSize: pageSize,
  //                                   onChange: (page, pageSize) => {
  //                                     setCurrentPage(page);
  //                                     setPageSize(pageSize);
  //                                   }
  //                                 }}

  //                                   />
  //                               </>
  //                                 ) : (
  //                                   // No data found message
  //                                   <div className="text-center py-4">
  //                                     <h5>No Data Found</h5>
  //                                   </div>
  //                                 )}

  //                         </div>

  //             </div>

  //         </div>

  //     </div>

  //     <FooterPage/>
  // </>
  //   )

  // const getColumnSearchProps = (dataIndex) => ({
  //   filterDropdown: ({
  //     setSelectedKeys,
  //     selectedKeys,
  //     confirm,
  //     clearFilters,
  //   }) => (
  //     <div style={{ padding: 8 }}>
  //       <Input
  //         placeholder={`Search ${dataIndex}`}
  //         value={selectedKeys[0]}
  //         onChange={(e) =>
  //           setSelectedKeys(e.target.value ? [e.target.value] : [])
  //         }
  //         onPressEnter={() => confirm()}
  //         style={{ width: 188, marginBottom: 8, display: "block" }}
  //       />
  //       <Button type="primary" onClick={() => confirm()}>
  //         Search
  //       </Button>
  //       <Button onClick={() => clearFilters() && setSelectedKeys([])}>
  //         Reset
  //       </Button>
  //     </div>
  //   ),
  //   onFilter: (value, record) =>
  //     record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  //   onFilterDropdownVisibleChange: (visible) => {
  //     if (visible) {
  //       setTimeout(() => this.searchInput.select(), 0);
  //     }
  //   },
  //   render: (text) => <div>{text}</div>,
  // });

  return (
    <>
      <HeaderPage />
      <div className="container-fluid">
        <div className="row px-1 px-lg-5 mt-2 d-flex align-items-center">
          <span className="componentTitle">
            <AiOutlineAlignRight /> Attendance Summary Report
          </span>
        </div>
        <div className="row m-0 px-lg-5 mb-5">
          <div className="row m-0 mb-5 shadow px-lg-3 py-4" style={{ border: "1px solid #d3d3d3" }}>

            <div className="row m-0 py-2">
              <div className="col-12 col-md-2 py-2"></div>
              <div className="col-12 col-md-8 py-2">
                <div className="row py-2">
                  <div className="col-12 col-md-6 pt-2">
                    <label>Select Class</label> <br />
                    <Select
                      className="w-100"
                      showSearch
                      value={selectClassName}
                      placeholder="Select Class"
                      onChange={(value) => setSelectClassName(value)}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {classOptions.map((option) => (
                        <Select.Option key={option.class_id} value={option.class_id}>
                          {option.classname}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-12 col-md-6 pt-2">
                    <label>Select Subject</label> <br />
                    <div className="row" style={{ height: "34px" }}>
                      <div className="col-12 col-md-2 d-flex align-items-center">
                        <Checkbox checked={isAllSubjectsChecked} onChange={handleCheckboxChange}>
                          All
                        </Checkbox>
                      </div>
                      {!isAllSubjectsChecked && (
                        <div className="col-12 col-md-10">
                          <Select
                            showSearch
                            className="w-100"
                            value={selectSubjectName}
                            placeholder="Select Subject"
                            onChange={(value) => setSelectSubjectName(value)}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {subjectOptions.map((option) => (
                              <Select.Option key={option.sub_id} value={option.sub_id}>
                                {option.subjectname}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 pt-4 pt-md-2">
                    <label>From Date</label> <br />
                    <DatePicker
                      className="w-100"
                      format="DD/MM/YYYY"
                      value={fromDate}
                      onChange={(date) => setFromDate(date)}
                    />
                  </div>
                  <div className="col-12 col-md-6 pt-2">
                    <label>To Date :</label> <br />
                    <DatePicker
                      className="w-100"
                      format="DD/MM/YYYY"
                      value={toDate}
                      onChange={(date) => setToDate(date)}
                    />
                  </div>
                  <div className="col-12 py-4 text-center">
                    <Button className="cancelBtn" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button className="saveBtn" onClick={handleSave}>
                      Show Data
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-2 py-2"></div>
            </div>
  
            {/* ======>> table data <<====== */}
              <div className="col-12 shadow" style={{ border: "1px solid #d3d3d3" }}>
              {data.subjects && data.subjects.length > 0 ? (
                  <>

                  {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                            <>
                    <div className="col-12 py-4 text-center text-md-end">
                      <Button
                        className="downloadBtn"
                        onClick={handleDownloadExcel}
                      >
                        Download Excel
                      </Button>
                      <Button
                        className="downloadBtn"
                        onClick={handleDownloadPdf}
                      >
                        Download Pdf
                      </Button>
                    </div>
                    <div className="row p-0 m-0 py-2" style={{ borderBottom: "1px solid grey" }}>
                      <div className="col-12 col-lg-6 text-start text-md-start">
                        <span style={{ fontWeight: 500 }}>Show entries &nbsp; </span>
                        <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                          <option value={10}>10</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                      <div className="col-12 col-lg-6 text-start text-md-end">
                        <span style={{ fontWeight: 500 }}>Search &nbsp; </span>
                        <Input
                          placeholder="Search Here"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          style={{ width: 200 }}
                        />
                      </div>
                    </div>
                    {/* Render table here */}
                    <Table
                      style={{ overflow: "auto" }}
                      dataSource={
                      data.subjects
                        ? data.subjects.flatMap((subject) =>
                            subject.students
                              .filter((student) =>
                                // Check if any column contains the search text
                              student.classname?.toLowerCase().includes(searchText.toLowerCase()) ||
                              student.subjectname?.toLowerCase().includes(searchText.toLowerCase()) ||
                              student.studentname?.toLowerCase().includes(searchText.toLowerCase())
                              )
                              .map((student) => ({
                                ...student,
                                classname: subject.classname,
                                subjectname: subject.subjectname,
                              }))
                          )
                        : []
                    } 
                      pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        onChange: (page, pageSize) => {
                          setCurrentPage(page);
                          setPageSize(pageSize);
                        },
                      }}
                      columns={[
                        {
                          title: "Roll No",
                          dataIndex: "roll_no",
                        },
                        {
                          title: "Class Name",
                          dataIndex: "classname",
                        },
                        {
                          title: "Subject Name",
                          dataIndex: "subjectname",
                        },
                        {
                          title: "Registration No",
                          dataIndex: "reg_no",
                        },
                        {
                          title: "Student Name",
                          dataIndex: "studentname",
                        },
                        {
                          title: "Lecture Count",
                          dataIndex: "lectCount",
                        },
                        {
                          title: "Attendance Count",
                          dataIndex: "studentCount",
                        },
                        {
                          title: "Attendance Percentage",
                          dataIndex: "attendancePercentage",
                        },
                      ]} />
                  </>
              )}
                  </>
                ) : (
                  <div className="text-center py-4">
                    <h5>No Data Found</h5>
                 </div>
                )}
              </div>


          </div>
        </div>
      </div>
      <FooterPage />
    </>
  );
  
  
};

export default AttendanceSummaryReport;
