import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { AiOutlineAlignRight } from 'react-icons/ai'
import { Tabs, Input, Button, DatePicker,Select, Table } from 'antd';
import { allLectureDataGet, classLectureDataGet, classMasterGet, employeeLectureDataGet, employeeMasterGet } from '../../APIs/API';
import axios from 'axios';
import { Option } from 'antd/es/mentions'
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import loaderImg from '../../Assets/loader1.gif'
import { useNavigate } from 'react-router-dom';

const LectureDataReport = () => {

  const navigate = useNavigate();
  //========== Check for session storage and redirect if empty======
  useEffect(() => {
    const token = sessionStorage.getItem('token'); 

    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const dateFormatList = ['DD/MM/YYYY'];

  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
 
  const [isLoading, setIsLoading] = useState(false);

// ##############################>>> ALL TABLE CODE START <<<#####################################

    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    

       //========== Fetch Table Data =========
       const handleShowAll = ()=>{
        setIsLoading(true);
        if (!fromDate || !toDate ) {
          toast.error("Please select all fields before saving");
          return;
      }       

         // Format dates to dd/mm/yyyy
         const formattedFromDate = fromDate ? formatDate(new Date(fromDate)) : null;
         const formattedToDate = toDate ? formatDate(new Date(toDate)) : null;         
      
        axios.get(`${allLectureDataGet}/${formattedFromDate}/${formattedToDate}`)
        .then(response => {        
            setData(response.data);
            setIsLoading(false); 
        })
        .catch(error => {
            console.error('Error fetching lecture data:', error);
        });
      }

      const columns = [
        {
          title: 'Sr. No.',
          key: 'index',
          render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
        },
        {
            title: 'Class Name',
          dataIndex: 'classname',
          key: 'classname',
        },
        {
          title: 'Subject Name',
          dataIndex: 'subjectname',
          key: 'subjectname',
        },
        {
          title: 'Teacher Name',
          dataIndex: 'teachername',
          key: 'teachername',
        },
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
        },
        {
          title: 'Time',
          dataIndex: 'time',
          key: 'time',
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
        },
        {
          title: 'Attended Count',
          dataIndex: 'count',
          key: 'count',
        },
        
      ];

      // Filter data based on search input
      const filteredData = data.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
      );

       // Handle Cancel button click
      const handleCancel = () => {  
        setFromDate();
        setToDate();
        setData([]); // Clear the data
      };
      
      // ==================== Export to Excel ====================
      const handleDownloadExcel = () => {
        if (data.length === 0) {
          toast.error("No data available to download");
          return;
        }
        const excelData = data.map((row, index) => ({
          "Sr. No.": index + 1,
          "Class Name": row.classname,
          "Subject Name": row.subjectname,
          "Teacher Name": row.teachername,
          "Date": row.date,
          "Time": row.time,
          "Description": row.description,
          "Attended Count": row.count,
        }));
      
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "All Lecture Data");
        XLSX.writeFile(workbook, 'All_Lecture_Data.xlsx');
      };

      // ==================== Export to PDF ====================
      const handleDownloadPdf = () => {
        if (data.length === 0) {
          toast.error("No data available to download");
          return;
        }

        const doc = new jsPDF();

        // Get page width and calculate the center position for the college name
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const collegeName = "Latthe Education Society's";
        const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
        const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

        doc.setFontSize(10); 
        const textWidth1 = doc.getTextWidth(collegeName);
        const centerX1 = (pageWidth - textWidth1) / 2; 
        doc.text(collegeName, centerX1, 10); 
      
        doc.setFontSize(17); 
        const textWidth2 = doc.getTextWidth(collegeName1);
        const centerX2 = (pageWidth - textWidth2) / 2;
        doc.text(collegeName1, centerX2, 16); 
    
        doc.setFontSize(7); 
        const textWidth3 = doc.getTextWidth(collegeName2);
        const centerX3 = (pageWidth - textWidth3) / 2;
        doc.text(collegeName2, centerX3, 20); 
      
        // Set font size for the teacher and date
        doc.setFontSize(10);

        // Add a border function that runs for every page
        const addBorder = () => {
          doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
        };

        // Convert the data to rows for jsPDF autotable
        const tableColumn = ['Sr No','Class Name', 'Subject Name', 'Teacher Name', 'Date', 'Time', 'Description', 'Attended Count'];
        const tableRows = data.map((row,index) => [
          index + 1,
          row.classname,
          row.subjectname,
          row.teachername,
          row.date,
          row.time,
          row.description,
          row.count
        ]);

        // Add the table to PDF
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
          startY: 25, // Adjust to position the table below the title
          styles: {
            lineWidth: 0.1, // Border thickness for table cells
            lineColor: [0, 0, 0], // Black border color
          },
          tableLineColor: [0, 0, 0], // Border color for table's outer frame
          tableLineWidth: 0.1, // Border thickness for the table's outer frame
            didDrawPage: (data) => {
              addBorder(); // Draw the border on every new page
            },
        });

        // Save the PDF
        doc.save('All_Lecture_Data.pdf');
      };

// $$$$$$$$$$$$$$$$$$$$$$$$$$$>>> ALL TABLE CODE END <<<$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

// ##############################>>> CLASS WISE TABLE CODE START <<<#####################################

const [data1, setData1] = useState([]);
const [classOptions, setClassOptions] = useState([]);
const [selectedClass, setSelectedClass] = useState(null);
const [fromDate1, setFromDate1] = useState(null);
const [toDate1, setToDate1] = useState(null);
const [searchText1, setSearchText1] = useState('');
const [pageSize1, setPageSize1] = useState(10);
const [currentPage1, setCurrentPage1] = useState(1);


        // Fetch Class Options
        const FetchClassOptions = () => {
          axios
            .get(classMasterGet) 
            .then((response) => {
              setClassOptions(response.data);
            })
            .catch((error) => {
              console.error('Error fetching class options:', error);
            });
        };

      useEffect(() => {
        FetchClassOptions();
      }, []);

   //========== Fetch Table Data =========
   const handleShowClassWise = ()=>{
    setIsLoading(true);
    if (!fromDate1 || !toDate1 || !selectedClass ) {
      toast.error("Please select all fields before saving");
      return;
  }       

     // Format dates to dd/mm/yyyy
     const formattedFromDate1 = fromDate1 ? formatDate(new Date(fromDate1)) : null;
     const formattedToDate1 = toDate1 ? formatDate(new Date(toDate1)) : null;         

    axios.get(`${classLectureDataGet}/${selectedClass}/${formattedFromDate1}/${formattedToDate1}`)
    .then(response => {       
        setData1(response.data); 
        setIsLoading(false);
    })
    .catch(error => {
        console.error('Error fetching lecture data:', error);
    });
  }

  const columns1 = [
    {
      title: 'Sr. No.',
      key: 'index',
      render: (_, __, index) => (currentPage1 - 1) * pageSize1 + index + 1,
    },
    {
        title: 'Class Name',
      dataIndex: 'classname',
      key: 'classname',
    },
    {
      title: 'Teacher Name',
      dataIndex: 'teachername',
      key: 'teachername',
    },
    {
      title: 'Subject Name',
      dataIndex: 'subjectname',
      key: 'subjectname',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Attended Count',
      dataIndex: 'count',
      key: 'count',
    },
    
  ];

  // Filter data based on search input
  const filteredData1 = data1.filter(item =>
  Object.values(item).some(value =>
    value.toString().toLowerCase().includes(searchText1.toLowerCase())
  )
  );

   // Handle Cancel button click
  const handleCancel1 = () => {  
    setSelectedClass();
    setFromDate1();
    setToDate1();
    setData1([]); 
  };
  
  // ==================== Export to Excel ====================
  const handleDownloadExcel1 = () => {
    if (data1.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const excelData1 = data1.map((row, index) => ({
      "Sr. No.": index + 1,
      "Class Name": row.classname,
      "Teacher Name": row.teachername,
      "Subject Name": row.subjectname,
      "Date": row.date,
      "Time": row.time,
      "Description": row.description,
      "Attended Count": row.count,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(excelData1);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Class Wise Lecture Data");
    XLSX.writeFile(workbook, 'Class_Wise_Lecture_Data.xlsx');
  };

  // ==================== Export to PDF ====================
  const handleDownloadPdf1 = () => {
    if (data1.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const doc = new jsPDF();

    // Get page width and calculate the center position for the college name
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

        const collegeName = "Latthe Education Society's";
        const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
        const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

        doc.setFontSize(10); 
    const textWidth1 = doc.getTextWidth(collegeName);
    const centerX1 = (pageWidth - textWidth1) / 2; 
    doc.text(collegeName, centerX1, 10); 
  
    doc.setFontSize(17); 
    const textWidth2 = doc.getTextWidth(collegeName1);
    const centerX2 = (pageWidth - textWidth2) / 2;
    doc.text(collegeName1, centerX2, 16); 

    doc.setFontSize(7); 
    const textWidth3 = doc.getTextWidth(collegeName2);
    const centerX3 = (pageWidth - textWidth3) / 2;
    doc.text(collegeName2, centerX3, 20); 
  
    // Set font size for the teacher and date
    doc.setFontSize(10);

    // Add a border function that runs for every page
    const addBorder = () => {
      doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
    };


    // Convert the data to rows for jsPDF autotable
    const tableColumn = ['Sr No','Class Name','Teacher Name', 'Subject Name', 'Date', 'Time', 'Description','Attended Count'];
    const tableRows = data1.map((row,index) => [
      index + 1,
      row.classname,
      row.teachername,
      row.subjectname,
      row.date,
      row.time,
      row.description,
      row.count,
    ]);

    // Add the table to PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25, // Adjust to position the table below the title
      styles: {
        lineWidth: 0.1, // Border thickness for table cells
        lineColor: [0, 0, 0], // Black border color
      },
      tableLineColor: [0, 0, 0], // Border color for table's outer frame
      tableLineWidth: 0.1, // Border thickness for the table's outer frame
        didDrawPage: (data1) => {
          addBorder(); // Draw the border on every new page
        },
    });

    // Save the PDF
    doc.save('Class_Wise_Lecture_Data.pdf');
  };

// $$$$$$$$$$$$$$$$$$$$$$$$$$$>>> CLASS WISE TABLE CODE END <<<$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

// ##############################>>> EMPLOYEE WISE TABLE CODE START <<<#####################################

const [data2, setData2] = useState([]);
const [employeeOptions, setEmployeeOptions] = useState([]);
const [selectedEmployee, setSelectedEmployee] = useState(null);
const [fromDate2, setFromDate2] = useState(null);
const [toDate2, setToDate2] = useState(null);
const [searchText2, setSearchText2] = useState('');
const [pageSize2, setPageSize2] = useState(10);
const [currentPage2, setCurrentPage2] = useState(1);


        // Fetch Class Options
        const FetchEmployeeOptions = () => {
          axios
            .get(employeeMasterGet) 
            .then((response) => {
              setEmployeeOptions(response.data);
            })
            .catch((error) => {
              console.error('Error fetching class options:', error);
            });
        };

      useEffect(() => {
        FetchEmployeeOptions();
      }, []);

   //========== Fetch Table Data =========
   const handleShowEmployeeWise = ()=>{
    setIsLoading(true);

    if (!fromDate2 || !toDate2 || !selectedEmployee ) {
      toast.error("Please select all fields before saving");
      return;
  }       

     // Format dates to dd/mm/yyyy
     const formattedFromDate2 = fromDate2 ? formatDate(new Date(fromDate2)) : null;
     const formattedToDate2 = toDate2 ? formatDate(new Date(toDate2)) : null;  
     

    axios.get(`${employeeLectureDataGet}/${selectedEmployee}/${formattedFromDate2}/${formattedToDate2}`)
    .then(response => {      
        setData2(response.data); 
        setIsLoading(false);
    })
    .catch(error => {
        console.error('Error fetching lecture data:', error);
    });
  }

  const columns2 = [
    {
      title: 'Sr. No.',
      key: 'index',
      render: (_, __, index) => (currentPage2 - 1) * pageSize2 + index + 1,
    },
    {
      title: 'Teacher Name',
      dataIndex: 'teachername',
      key: 'teachername',
    },
    {
        title: 'Class Name',
      dataIndex: 'classname',
      key: 'classname',
    },
    {
      title: 'Subject Name',
      dataIndex: 'subjectname',
      key: 'subjectname',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Attended Count',
      dataIndex: 'count',
      key: 'count',
    },
    
  ];

  // Filter data based on search input
  const filteredData2 = data2.filter(item =>
  Object.values(item).some(value =>
    value.toString().toLowerCase().includes(searchText.toLowerCase())
  )
  );


   // Handle Cancel button click
  const handleCancel2 = () => {  
    setSelectedEmployee();
    setFromDate2();
    setToDate2();
    setData2([]); 
  };
  
  // ==================== Export to Excel ====================
  const handleDownloadExcel2 = () => {
    if (data2.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const excelData2 = data2.map((row, index) => ({
      "Sr. No.": index + 1,
      "Teacher Name": row.teachername,
      "Class Name": row.classname,
      "Subject Name": row.subjectname,
      "Date": row.date,
      "Time": row.time,
      "Description": row.description,
      "Attended Count": row.count,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(excelData2);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Employee Wise Lecture Data');
    XLSX.writeFile(workbook, 'Employee_Wise_Lecture_Data.xlsx');


  };

  // ==================== Export to PDF ====================
  const handleDownloadPdf2 = () => {
    if (data2.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const doc = new jsPDF();

    // Get page width and calculate the center position for the college name
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

        const collegeName = "Latthe Education Society's";
        const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
        const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";

        doc.setFontSize(10); 
        const textWidth1 = doc.getTextWidth(collegeName);
        const centerX1 = (pageWidth - textWidth1) / 2; 
        doc.text(collegeName, centerX1, 10); 
      
        doc.setFontSize(17); 
        const textWidth2 = doc.getTextWidth(collegeName1);
        const centerX2 = (pageWidth - textWidth2) / 2;
        doc.text(collegeName1, centerX2, 16); 
    
        doc.setFontSize(7); 
        const textWidth3 = doc.getTextWidth(collegeName2);
        const centerX3 = (pageWidth - textWidth3) / 2;
        doc.text(collegeName2, centerX3, 20); 
      
        // Set font size for the teacher and date
        doc.setFontSize(10);

   // Add a border function that runs for every page
   const addBorder = () => {
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
  };

    // Convert the data to rows for jsPDF autotable
    const tableColumn = ['Sr No','Teacher Name','Class Name', 'Subject Name', 'Date', 'Time', 'Description','Attended Count'];
    const tableRows = data2.map((row,index) => [
      index + 1,
      row.teachername,
      row.classname,
      row.subjectname,
      row.date,
      row.time,
      row.description,
      row.count,
    ]);

    // Add the table to PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 25, // Adjust to position the table below the title
      styles: {
        lineWidth: 0.1, // Border thickness for table cells
        lineColor: [0, 0, 0], // Black border color
      },
      tableLineColor: [0, 0, 0], // Border color for table's outer frame
      tableLineWidth: 0.1, // Border thickness for the table's outer frame
        didDrawPage: (data2) => {
          addBorder(); // Draw the border on every new page
        },
    });

    // Save the PDF
    doc.save('Employee_Wise_Lecture_Data.pdf');
  };

// $$$$$$$$$$$$$$$$$$$$$$$$$$$>>> EMPLOYEE WISE TABLE CODE END <<<$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  // ======Tabs Code===========================================================================================

  const TabContent1 = () => (
    <div className='row p-0 m-0' style={{border:"1px solid #B3C8CF"}}>

        <div className='row p-0 px-md-5 m-0'>

          <div className='row p-0 px-md-5 m-0 py-3 pb-5'>
                <div className='col-12 col-md-2 pt-2'></div>
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>From Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                            <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={fromDate}  onChange={(date) => setFromDate(date)} />
                        </div>
                    </div>
                </div> 
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>To Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                        <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={toDate}  onChange={(date) => setToDate(date)} />
                        </div>
                    </div> 
                </div> 
                <div className='col-12 col-md-2 pt-4 pt-md-2 d-flex align-items-center justify-content-center'>
                      <Button className='saveBtn' onClick={handleShowAll}>Show Data</Button>
                      <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
                </div>
                <div className='col-12 col-md-2'></div>
          </div>

        </div>

         {/* ======>> table data <<====== */}
         <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

         {data.length > 0 ? (
          <>
                       {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                            <>
                                <div className='col-12 py-4 text-end'>
                                    <Button className='downloadBtn' onClick={handleDownloadExcel}> Download Excel</Button>
                                    <Button className='downloadBtn' onClick={handleDownloadPdf}>Download Pdf</Button>
                                </div>
                          
                                <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                                    <div className='col-12 col-lg-6 text-start text-md-start'>
                                    <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                                            <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                                <option value={10}>10</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                    </div>
                                    <div className='col-12 col-lg-6 text-start text-md-end'>
                                    <span style={{fontWeight:500}}>Search &nbsp; </span>
                                                          <Input
                                                          placeholder="Search Here"
                                                          value={searchText}
                                                          onChange={(e) => {
                                                                    setSearchText(e.target.value); // Update state on every keystroke
                                                                }}
                                                          style={{ width: 200 }}
                                                          />
                                    </div>                   
                                </div>

                                <Table style={{overflow:"auto"}} columns={columns} 
                                  dataSource={filteredData}
                                  pagination={{
                                  current: currentPage,
                                  pageSize: pageSize,
                                  onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                    setPageSize(pageSize);
                                  }
                                }}   />
                            </>
             )}   
          </>
          ) : (
              <div className="text-center py-4">
                    <h5>No Data Found</h5>
              </div>
          )}


          </div>

    </div>
  );
  
  const TabContent2 = () => (
    <div className='row p-0 m-0' style={{border:"1px solid #B3C8CF"}}>

        <div className='row p-0 px-md-5 m-0'>

          <div className='row p-0 px-md-5 m-0 py-3 pb-5'>
                <div className='col-12 col-md-4 pt-2'>
                    <div className='row p-0 m-0'>
                          <div className='col-4 col-md-4 p-0'>
                              <label>Select Class :</label>
                          </div>
                          <div className='col-8 col-md-8'>
                              <Select className='w-100' showSearch
                                  value={selectedClass} placeholder="Select Class"
                                  onChange={(value) => setSelectedClass(value)}
                                  filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                  >
                                {classOptions.map((option) => (
                                  <Option key={option.class_id} value={option.class_id}>
                                    {option.classname}
                                  </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>From Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                            <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={fromDate1}  onChange={(date) => setFromDate1(date)} />
                        </div>
                    </div>
                </div> 
                <div className='col-12 col-md-3 pt-2'>
                    <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                              <label>To Date :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                        <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={toDate1}  onChange={(date) => setToDate1(date)} />
                        </div>
                    </div> 
                </div> 
                <div className='col-12 col-md-2 pt-4 pt-md-2 d-flex align-items-center justify-content-center'>
                      <Button className='saveBtn' onClick={handleShowClassWise}>Show Data</Button>
                      <Button className='cancelBtn' onClick={handleCancel1}>Cancel</Button>
                </div>
          </div>

        </div>

         {/* ======>> table data <<====== */}
         <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

         {data1.length > 0 ? (
          <>
                              {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                              <>
                                  <div className='col-12 py-4 text-end'>
                                      <Button className='downloadBtn' onClick={handleDownloadExcel1}> Download Excel</Button>
                                      <Button className='downloadBtn' onClick={handleDownloadPdf1}>Download Pdf</Button>
                                  </div>
                                  
                                  <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                                      <div className='col-12 col-lg-6 text-start text-md-start'>
                                      <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                                              <select onChange={(e) => setPageSize1(Number(e.target.value))} value={pageSize1}>
                                                  <option value={10}>10</option>
                                                  <option value={50}>50</option>
                                                  <option value={100}>100</option>
                                              </select>
                                      </div>
                                      <div className='col-12 col-lg-6 text-start text-md-end'>
                                      <span style={{fontWeight:500}}>Search &nbsp; </span>
                                                            <Input
                                                            placeholder="Search Here"
                                                            value={searchText1}
                                                            onChange={(e) => setSearchText1(e.target.value)}
                                                            style={{ width: 200 }}
                                                            />
                                      </div>                   
                                  </div>

                                  <Table style={{overflow:"auto"}} columns={columns1} 
                                    dataSource={filteredData1}
                                    pagination={{
                                    current: currentPage1,
                                    pageSize: pageSize1,
                                    onChange: (page, pageSize) => {
                                      setCurrentPage1(page);
                                      setPageSize1(pageSize);
                                    }
                                  }} />
                              </>
                            )}
          </>
          ) : (
              <div className="text-center py-4">
                    <h5>No Data Found</h5>
              </div>
          )}


          </div>

    </div>
  );
  
  const TabContent3 = () => (
    <div className='row p-0 m-0' style={{border:"1px solid #B3C8CF"}}>

      <div className='row p-0 px-md-5 m-0'>

        <div className='row p-0 px-md-5 m-0 py-3 pb-5'>
              <div className='col-12 col-md-4 pt-2'>
                  <div className='row p-0 m-0'>
                        <div className='col-4 col-md-4 p-0'>
                            <label>Select Employee :</label>
                        </div>
                        <div className='col-8 col-md-8'>
                            <Select className='w-100' showSearch
                                value={selectedEmployee} placeholder="Select Employee"
                                onChange={(value) => setSelectedEmployee(value)}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                                >
                              {employeeOptions.map((option) => (
                                <Option key={option.teacher_id} value={option.teacher_id}>
                                  {option.teachername}
                                </Option>
                              ))}
                          </Select>
                      </div>
                  </div>
              </div>
              <div className='col-12 col-md-3 pt-2'>
                  <div className='row p-0 m-0'>
                      <div className='col-4 col-md-4 p-0'>
                            <label>From Date :</label>
                      </div>
                      <div className='col-8 col-md-8'>
                          <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={fromDate2}  onChange={(date) => setFromDate2(date)} />
                      </div>
                  </div>
              </div> 
              <div className='col-12 col-md-3 pt-2'>
                  <div className='row p-0 m-0'>
                      <div className='col-4 col-md-4 p-0'>
                            <label>To Date :</label>
                      </div>
                      <div className='col-8 col-md-8'>
                      <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={toDate2}  onChange={(date) => setToDate2(date)} />
                      </div>
                  </div> 
              </div> 
              <div className='col-12 col-md-2 pt-4 pt-md-2 d-flex align-items-center justify-content-center'>
                    <Button className='saveBtn' onClick={handleShowEmployeeWise}>Show Data</Button>
                    <Button className='cancelBtn' onClick={handleCancel2}>Cancel</Button>
              </div>
        </div>

      </div>

      {/* ======>> table data <<====== */}
      <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>
                {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                  <>
                      {data2.length > 0 ? (                
                      <>
                             {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                                  <>
                                              <div className='col-12 py-4 text-end'>
                                                  <Button className='downloadBtn' onClick={handleDownloadExcel2}> Download Excel</Button>
                                                  <Button className='downloadBtn' onClick={handleDownloadPdf2}>Download Pdf</Button>
                                              </div>
                                              
                                            <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                                                <div className='col-12 col-lg-6 text-start text-md-start'>
                                                <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                                                        <select onChange={(e) => setPageSize2(Number(e.target.value))} value={pageSize2}>
                                                            <option value={10}>10</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                </div>
                                                <div className='col-12 col-lg-6 text-start text-md-end'>
                                                <span style={{fontWeight:500}}>Search &nbsp; </span>
                                                                        <Input
                                                                        placeholder="Search Here"
                                                                        value={searchText2}
                                                                        onChange={(e) => setSearchText2(e.target.value)}
                                                                        style={{ width: 200 }}
                                                                        />
                                                </div>                   
                                            </div>

                                            <Table style={{overflow:"auto"}} columns={columns2} 
                                              dataSource={filteredData2}
                                              pagination={{
                                              current: currentPage2,
                                              pageSize: pageSize2,
                                              onChange: (page, pageSize) => {
                                                setCurrentPage1(page);
                                                setPageSize1(pageSize);
                                              }
                                            }}           
                                              />
                                  </>
                            )}    
                              

                      </>

                      ) : (
                          <div className="text-center py-4">
                                <h5>No Data Found</h5>
                          </div>
                      )}
                  </>
              )}
        </div>

   </div>
  );

  const items = [
    {
      key: '1',
      label: 'All',
      children: <TabContent1 />,
    },
    {
      key: '2',
      label: 'Class Wise',
      children: <TabContent2 />,
    },
    {
      key: '3',
      label: 'Employee Wise',
      children: <TabContent3 />,
    },
  ];

  //===========================================================================

  return (
    <>
    <HeaderPage/>

    <div className='container-fluid'>

        <div className='row px-1 px-lg-5 mt-2 d-flex align-items-center'>
            <span className='componentTitle'> <AiOutlineAlignRight /> Lecture Data Report</span>
        </div>

        <div className='row m-0 px-lg-5 mb-5'>
            <div className='row m-0 mb-5 shadow px-lg-3 py-4' style={{border:"1px solid #d3d3d3"}}>

                              <div className='col-12 py-2'>
                                <Tabs defaultActiveKey="1" items={items}  />
                              </div>

            </div>
        </div>

    </div>

    <FooterPage/>
</>
  )
}

export default LectureDataReport