import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { AiOutlineAlignRight } from 'react-icons/ai'
import { classMasterGet, employeeMasterGet, subjectLectureDataGet, subjectMasterGet } from '../../APIs/API'
import { Button, Input,  Select, Table } from 'antd'
import loaderImg from '../../Assets/loader1.gif'
import { Option } from 'antd/es/mentions'
import axios from 'axios'
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom'

const SubjectLectureDataReport = () => {

  const navigate = useNavigate();
  //========== Check for session storage and redirect if empty======
  useEffect(() => {
    const token = sessionStorage.getItem('token'); 

    if (!token) {
      navigate('/');
    }
  }, [navigate]);

  const [isLoading, setIsLoading] = useState(false);
  const [classOptions, setClassOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [data, setData] = useState([]);

  const [selectClassName, setSelectClassName] = useState(null);
  const [selectSubjectName, setSelectSubjectName] = useState(null);
  const [selectTeacherName, setSelectTeacherName] = useState(null);


  const [searchText, setSearchText] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);


    //========== Fetch Table Data =========
    const handleSave = ()=>{
      setIsLoading(true);
      if (!selectClassName || !selectSubjectName || !selectTeacherName ) {
        toast.error("Please select all fields before saving");
        return;
    } 
    
      axios.get(`${subjectLectureDataGet}/${selectClassName}/${selectSubjectName}/${selectTeacherName}`)
      .then(response => {        
          setData(response.data); 
          setIsLoading(false);
      })
      .catch(error => {
          console.error('Error fetching subject lecture data:', error);
      });
    }

     // Fetch Class Options
     const FetchClassOptions = () => {
      axios
        .get(classMasterGet)
        .then((response) => {
          setClassOptions(response.data);
        })
        .catch((error) => {
          console.error('Error fetching class options:', error);
        });
    };

      // Fetch Subject Options
      const FetchSubjectOptions = () => {
        axios
          .get(subjectMasterGet)
          .then((response) => {
            setSubjectOptions(response.data);
          })
          .catch((error) => {
            console.error('Error fetching subject options:', error);
          });
      };

       // Fetch Teacher Options
       const FetchTeacherOptions = () => {
        axios
          .get(employeeMasterGet)
          .then((response) => {
            setTeacherOptions(response.data);
          })
          .catch((error) => {
            console.error('Error fetching teacher options:', error);
          });
      };


     useEffect(() => {
       FetchClassOptions();
       FetchSubjectOptions();
       FetchTeacherOptions();
      }, []);

 // Handle Cancel button click
    const handleCancel = () => {
      setSelectClassName(null);
      setSelectSubjectName(null);
      setSelectTeacherName(null);
      setData([]); // Clear the data
  };

   // ==================== Export to Excel ====================
   const handleDownloadExcel = () => {
    if (data.length === 0) {
      toast.error("No data available to download");
      return;
    }

    const excelData = data.map((row, index) => ({
      "Sr. No.": index + 1,
      "Class Name": row.classname,
      "Subject Name": row.subjectname,
      "Teacher Name": row.teachername,
      "Date": row.date,
      "Time": row.time,
      "Description": row.description,
      "Attended Count": row.student_count,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subject Lecture Data Report");
    XLSX.writeFile(workbook, 'Subject_Lecture_Data_Report.xlsx');
  };

  // ==================== Export to PDF ====================
  // const handleDownloadPdf = () => {
  //   if (data.length === 0) {
  //     toast.error("No data available to download");
  //     return;
  //   }

  //   const doc = new jsPDF();

  //    // Get page width and calculate the center position for the college name
  //    const pageWidth = doc.internal.pageSize.getWidth();
  //    const pageHeight = doc.internal.pageSize.getHeight();

  //    // Draw a rectangle (border) around the entire page with some margin
  //    doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side

  //    const textWidth = doc.getTextWidth(collegeName);
  //    const centerX = (pageWidth - textWidth) / 2; // Calculate the center position

  //    // Add College Name
  //    doc.text(collegeName, centerX, 15); // Title at the top

  //   // Convert the data to rows for jsPDF autotable
  //   const tableColumn = ['Class Name', 'Subject Name', 'Teacher Name', 'Date', 'Time', 'Description', 'Attended Count'];
  //   const tableRows = data.map(row => [
  //     row.classname,
  //     row.subjectname,
  //     row.teachername,
  //     row.lec_date,
  //     row.lec_time,
  //     row.description,
  //     row.student_count
  //   ]);

  //   // Add the table to PDF
  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 20, // Adjust to position the table below the title
  //   });

  //   // Save the PDF
  //   doc.save('Subject_Lecture_Data.pdf');
  // };


  // const handleDownloadPdf = () => {
  //   if (data.length === 0) {
  //     toast.error("No data available to download");
  //     return;
  //   }
  
  //   const doc = new jsPDF();
  
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  
  //   doc.rect(5, 5, pageWidth - 10, pageHeight - 10);
  
  //   const collegeName = "Latthe Education Society's";
  //   const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
  //   const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";
  
  //   doc.setFontSize(10);
  //   const textWidth1 = doc.getTextWidth(collegeName);
  //   const centerX1 = (pageWidth - textWidth1) / 2;
  //   doc.text(collegeName, centerX1, 13);

  //   doc.setFontSize(17);
  //   const textWidth2 = doc.getTextWidth(collegeName1);
  //   const centerX2 = (pageWidth - textWidth2) / 2; 
  //   doc.text(collegeName1, centerX2, 20);

  //   doc.setFontSize(7);
  //   const textWidth3 = doc.getTextWidth(collegeName2);
  //   const centerX3 = (pageWidth - textWidth3) / 2; 
  //   doc.text(collegeName2, centerX3, 25);
    
  
  //   const tableColumn = ['Class Name', 'Subject Name', 'Teacher Name', 'Date', 'Time', 'Description', 'Attended Count'];
  //   const tableRows = data.map(row => [
  //     row.classname,
  //     row.subjectname,
  //     row.teachername,
  //     row.lec_date,
  //     row.lec_time,
  //     row.description,
  //     row.student_count
  //   ]);
  
  //   // Add the table to PDF
  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 30, // Adjust to position the table below the titles
  //   });
  
  //   // Save the PDF
  //   doc.save('Subject_Lecture_Data.pdf');
  // };

  // const handleDownloadPdf = () => {
  //   if (data.length === 0) {
  //     toast.error("No data available to download");
  //     return;
  //   }
  
  //   const doc = new jsPDF();
  
  //   // Get page width and calculate the center position for the college name
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const pageHeight = doc.internal.pageSize.getHeight();
  
  //   // Draw a rectangle (border) around the entire page with some margin
  //   doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
  
  //   const collegeName = "Latthe Education Society's";
  //   const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
  
  //   // Get teacher name and date range from the API (assuming it is part of your data)
  //   const teacherName = data[0].teachername; // For example, taking the first teacher name
  //   const fromDate = data[0].lec_date; // For example, taking the first lecture date as fromDate
  //   const toDate = data[data.length - 1].lec_date; // Taking the last lecture date as toDate
  
  //   // Set font size for the college name
  //   doc.setFontSize(10); // Smaller font size for collegeName
  //   const textWidth1 = doc.getTextWidth(collegeName);
  //   const centerX1 = (pageWidth - textWidth1) / 2; // Center position for the first name
  
  //   // Add College Name
  //   doc.text(collegeName, centerX1, 15); // First title at the top
  
  //   // Set font size for the second college name
  //   doc.setFontSize(12); // Default or slightly larger font for collegeName1
  //   const textWidth2 = doc.getTextWidth(collegeName1);
  //   const centerX2 = (pageWidth - textWidth2) / 2; // Center position for the second name
  
  //   // Add College Name 1 below the first one
  //   doc.text(collegeName1, centerX2, 25); // Second title below the first
  
  //   // Add Teacher Name and Date Range
  //   doc.setFontSize(10); // Set smaller font size for teacher name and dates
  //   const teacherText = `Teacher: ${teacherName}`;
  //   const dateRange = `From: ${fromDate} - To: ${toDate}`;
  
  //   const textWidthTeacher = doc.getTextWidth(teacherText);
  //   const textWidthDate = doc.getTextWidth(dateRange);
  
  //   const centerXTeacher = (pageWidth - textWidthTeacher) / 2;
  //   const centerXDate = (pageWidth - textWidthDate) / 2;
  
  //   // Display teacher name and date range below collegeName1
  //   doc.text(teacherText, centerXTeacher, 35); // Teacher name below collegeName1
  //   doc.text(dateRange, centerXDate, 40); // Date range below teacher name
  
  //   // Convert the data to rows for jsPDF autotable
  //   const tableColumn = ['Class Name', 'Subject Name', 'Teacher Name', 'Date', 'Time', 'Description', 'Attended Count'];
  //   const tableRows = data.map(row => [
  //     row.classname,
  //     row.subjectname,
  //     row.teachername,
  //     row.lec_date,
  //     row.lec_time,
  //     row.description,
  //     row.student_count
  //   ]);
  
  //   // Add the table to PDF
  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 45, // Adjust to position the table below the teacher name and date range
  //   });
  
  //   // Save the PDF
  //   doc.save('Subject_Lecture_Data.pdf');
  // };
  
  const handleDownloadPdf = () => {
    if (data.length === 0) {
      toast.error("No data available to download");
      return;
    }
  
    const doc = new jsPDF();
  
    // Get page width and calculate the center position for the college name
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
  
     // Add a border function that runs for every page
     const addBorder = () => {
      doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // 5 units margin on each side
    };
  
    const collegeName = "Latthe Education Society's";
    const collegeName1 = "Narayandas Sarwottamdas Soti Law College, Sangli";
    const collegeName2 = "Permanent Affiliation of Shivaji University | Recognition Bar Council of India, New Delhi | Recognition of Govt. of Maharashtra Religious (Jain) Minority Institution";
  
  
    doc.setFontSize(10); 
    const textWidth1 = doc.getTextWidth(collegeName);
    const centerX1 = (pageWidth - textWidth1) / 2; 
    doc.text(collegeName, centerX1, 10); 
  
    doc.setFontSize(17); 
    const textWidth2 = doc.getTextWidth(collegeName1);
    const centerX2 = (pageWidth - textWidth2) / 2;
    doc.text(collegeName1, centerX2, 16); 

    doc.setFontSize(7); 
    const textWidth3 = doc.getTextWidth(collegeName2);
    const centerX3 = (pageWidth - textWidth3) / 2;
    doc.text(collegeName2, centerX3, 20); 
  
    // Set font size for the teacher and date
    doc.setFontSize(10);
  
  
    const tableColumn = ['Sr No','Class Name', 'Subject Name', 'Teacher Name', 'Date', 'Time', 'Description', 'Attended Count'];
    const tableRows = data.map((row,index) => [
      index + 1,
      row.classname,
      row.subjectname,
      row.teachername,
      row.lec_date,
      row.lec_time,
      row.description,
      row.student_count
    ]);
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30,
      styles: {
        lineWidth: 0.1, // Border thickness for table cells
        lineColor: [0, 0, 0], // Black border color
      },
      tableLineColor: [0, 0, 0], // Border color for table's outer frame
      tableLineWidth: 0.1, // Border thickness for the table's outer frame
        didDrawPage: (data) => {
          // This callback is called for each page created by autoTable
          addBorder(); // Draw the border on every new page
        },
    });
  
    doc.save('Subject_Lecture_Data.pdf');
  };
  
  

   // =====================TABLE CODE=========================

   const columns = [
    {
      title: 'Sr. No.',
      key: 'index',
      render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
        title: 'Class Name',
      dataIndex: 'classname',
      key: 'classname',
    },
    {
      title: 'Subject Name',
      dataIndex: 'subjectname',
      key: 'subjectname',
    },
    {
      title: 'Teacher Name',
      dataIndex: 'teachername',
      key: 'teachername',
    },
    {
      title: 'Date',
      dataIndex: 'lec_date',
      key: 'lec_date',
    },
    {
      title: 'Time',
      dataIndex: 'lec_time',
      key: 'lec_time',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Attended Count',
      dataIndex: 'student_count',
      key: 'student_count',
    },
    
  ];


      // Filter data based on search input
      const filteredData = data.filter(item =>
      Object.values(item).some(value =>
        value.toString().toLowerCase().includes(searchText.toLowerCase())
      )
      );

//===============================================================================================

  return (
    <>
    <HeaderPage/>

    <div className='container-fluid'>

        <div className='row px-1 px-lg-5 mt-2 d-flex align-items-center'>
            <span className='componentTitle'> <AiOutlineAlignRight /> Subject Lecture Data Report</span>
        </div>

        <div className='row m-0 px-lg-5 mb-5'>

            <div className='row m-0 mb-5 shadow px-lg-3 py-4' style={{border:"1px solid #d3d3d3"}}>

                      <div className='row m-0 py-2'>
                             <div className='col-12 col-md-6 col-lg-4 pt-2'>
                                  <label>Select Class</label> <br/>
                                            <Select className='w-100' showSearch
                                                value={selectClassName} placeholder="Select Class"
                                                onChange={(value) => setSelectClassName(value)}
                                                filterOption={(input, option) =>
                                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {classOptions.map((option) => (
                                                    <Option key={option.class_id} value={option.class_id}>
                                                        {option.classname}
                                                    </Option>
                                                ))}
                                            </Select>
                              </div>
                              <div className='col-12 col-md-6 col-lg-4 pt-2'>
                                  <label>Select Subject</label> <br/>
                                            <Select className='w-100' showSearch
                                                value={selectSubjectName} placeholder="Select Subject"
                                                onChange={(value) => setSelectSubjectName(value)}
                                                filterOption={(input, option) =>
                                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {subjectOptions.map((option) => (
                                                    <Option key={option.sub_id} value={option.sub_id}>
                                                        {option.subjectname}
                                                    </Option>
                                                ))}
                                            </Select>
                              </div>
                              <div className='col-12 col-md-6 col-lg-4 pt-2'>
                                  <label>Select Teacher</label> <br/>
                                            <Select className='w-100' showSearch
                                                value={selectTeacherName} placeholder="Select Teacher"
                                                onChange={(value) => setSelectTeacherName(value)}
                                                filterOption={(input, option) =>
                                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                            >
                                                {teacherOptions.map((option) => (
                                                    <Option key={option.teacher_id} value={option.teacher_id}>
                                                        {option.teachername}
                                                    </Option>
                                                ))}
                                            </Select>
                              </div>

                              <div className='col-12 py-4 text-center'>
                                <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
                                <Button className='saveBtn' onClick={handleSave}>Show Data</Button>
                              </div>

                      </div>

                          {/* ======>> table data <<====== */}
                        <div className='col-12 shadow' style={{border:"1px solid #d3d3d3"}}>

                        {data.length > 0 ? (
                          <>

                          {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                                  <>
                              <div className='col-12 py-4 text-end'>
                                <Button className='downloadBtn' onClick={handleDownloadExcel}> Download Excel</Button>
                                <Button className='downloadBtn' onClick={handleDownloadPdf}>Download Pdf</Button>
                              </div>


                              <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                                  <div className='col-12 col-lg-6 text-start text-md-start'>
                                  <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                                          <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                              <option value={10}>10</option>
                                              <option value={50}>50</option>
                                              <option value={100}>100</option>
                                          </select>
                                  </div>
                                  <div className='col-12 col-lg-6 text-start text-md-end'>
                                  <span style={{fontWeight:500}}>Search &nbsp; </span>
                                      <Input
                                      placeholder="Search Here"
                                      value={searchText}
                                      onChange={(e) => setSearchText(e.target.value)}
                                      style={{ width: 200 }}
                                      />
                                  </div>                   
                              </div>

                                <Table style={{overflow:"auto"}} columns={columns} 
                                  dataSource={filteredData}
                                  pagination={{
                                  current: currentPage,
                                  pageSize: pageSize,
                                  onChange: (page, pageSize) => {
                                    setCurrentPage(page);
                                    setPageSize(pageSize);
                                  }
                                  }}  />

                              </>
                           )}
                              </>
                                ) : (
                                  // No data found message
                                  <div className="text-center py-4">
                                    <h5>No Data Found</h5>
                                  </div>
                                )}

                        </div>

            </div>

        </div>

    </div>

    <FooterPage/>
</>
  )
}

export default SubjectLectureDataReport