import { Route, Routes } from 'react-router-dom';
import './App.css';
import LoginPage from './Components/CommonComponents/LoginPage';
import CancelMember from './Components/CommonComponents/OtherComponents/CancelMember';
import PrivacyPolicy from './Components/CommonComponents/OtherComponents/PrivacyPolicy';

import Dashboard from './Components/MainComponents/Dashboard';
import SubjectAllotment from './Components/MainComponents/SubjectAllotment';
import CreatedLectures from './Components/MainComponents/CreatedLectures';
import RegisteredStudents from './Components/MainComponents/RegisteredStudents';
// ====MASTERS=============================================================
import EmployeeMaster from './Components/MasterComponents/EmployeeMaster';
import SubjectMaster from './Components/MasterComponents/SubjectMaster';
import StudentMaster from './Components/MasterComponents/StudentMaster';
import ClassMaster from './Components/MasterComponents/ClassMaster';
// ====REPORTS=============================================================
import SubjectLectureDataReport from './Components/ReportComponents/SubjectLectureDataReport';
import LectureDataReport from './Components/ReportComponents/LectureDataReport';
import AttendanceDetailsReport from './Components/ReportComponents/AttendanceDetailsReport';
import AttendanceSummaryReport from './Components/ReportComponents/AttendanceSummaryReport';




function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/c-member" element={<CancelMember />} />
      <Route path="/pp" element={<PrivacyPolicy />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/sa" element={<SubjectAllotment />} />
      <Route path="/cl" element={<CreatedLectures />} />
      <Route path="/rs" element={<RegisteredStudents />} />

      {/* ====== MASTERS ====== */}
      <Route path="/m-cm" element={<ClassMaster />} />
      <Route path="/m-sm" element={<StudentMaster />} />
      <Route path="/m-subm" element={<SubjectMaster />} />
      <Route path="/m-em" element={<EmployeeMaster />} />

      {/* ====== REPORTS ====== */}
      <Route path="/r-asr" element={<AttendanceSummaryReport />} />
      <Route path="/r-adr" element={<AttendanceDetailsReport />} />
      <Route path="/r-ldr" element={<LectureDataReport />} />
      <Route path="/r-sldr" element={<SubjectLectureDataReport />} />


    </Routes>
    </>
  );
}

export default App;
