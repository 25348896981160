import React from 'react'

const FooterPage = () => {

    const currentYear = new Date().getFullYear(); // Get current year

  return (
    <>
        <div className='container-fluid fixed-bottom' style={{backgroundImage:"linear-gradient( 190deg,  rgb(4, 74, 148) 23.3%, rgb(65, 193, 253) 84.7% )",color:"white",fontSize:"13px"}}>
            <div className='row p-0 py-2 d-flex text-center align-items-center'>
                <span> &copy; {currentYear} Narayandas Sarwottamdas Soti Law College, Sangli | Developed By : TechnoAarv Solution</span>
            </div>
        </div>
    </>
  )
}

export default FooterPage