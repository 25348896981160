import React, { useEffect, useState } from 'react'
import HeaderPage from '../CommonComponents/HeaderPage'
import FooterPage from '../CommonComponents/FooterPage'
import { AiOutlineAlignRight } from 'react-icons/ai'
import loaderImg from '../../Assets/loader1.gif'
import { Button, DatePicker, Input, Modal, Switch, Table } from 'antd'
import axios from 'axios'
import { FaUserEdit } from 'react-icons/fa'
import { createdLectureGet, createdLectureStatusUpdate } from '../../APIs/API'
import { toast } from 'react-toastify'
import { MdEditDocument } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

const CreatedLectures = () => {

  const navigate = useNavigate();

  const dateFormatList = ['DD/MM/YYYY'];

  const formatDate = (date) => {
    if (!date) return null;
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  //========== Check for session storage and redirect if empty======
  useEffect(() => {
    const token = sessionStorage.getItem('token'); 

    if (!token) {
      navigate('/');
    }
  }, [navigate]);

    const [isLoading, setIsLoading] = useState(false);

    // =====================TABLE CODE=========================
    const [data, setData] = useState([]);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const [toggleStatus, setToggleStatus] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedLecture, setSelectedLecture] = useState(null);
    //========== Fetch Table Data =========

          const FetchTableData = ()=>{
            setIsLoading(true);

            if (!fromDate || !toDate ) {
              toast.error("Please select all fields before saving");
              setIsLoading(false); 
              return;
          }       
    
             // Format dates to dd/mm/yyyy
             const formattedFromDate = fromDate ? formatDate(new Date(fromDate)) : null;
             const formattedToDate = toDate ? formatDate(new Date(toDate)) : null;         
          
            axios.get(`${createdLectureGet}/${formattedFromDate}/${formattedToDate}`)
            .then(response => {        
                setData(response.data); 
            })
            .catch(error => {
                console.error('Error fetching attendance data:', error);
            })
            .finally(() => {
              setIsLoading(false); 
            });
          }


          const handleCancel = () => {  
            setFromDate();
            setToDate();
            setData([]); // Clear the data
          };

    // Open Modal and set initial data
    const handleEdit = (record) => {
      setSelectedLecture(record);  // Store selected lecture details
      setToggleStatus(record.lec_status === 1);  // Initialize toggle based on status
      setIsModalOpen(true);
    };

    // Handle toggle change
    const handleToggleChange = (checked) => {
      setToggleStatus(checked);
    };

    // Submit status update
    const handleStatusUpdate = () => {
          const updatedStatus = toggleStatus ? 1 : 2;
          axios.put(`${createdLectureStatusUpdate}/${selectedLecture.lec_id}/${updatedStatus}`)
          .then(response => {
              console.log('Status updated:====', response.data);
              if (response.data=="NOTFOUND") {
                  toast.warn("Data not found!!")
              }
              FetchTableData();  // Refresh table data
              setIsModalOpen(false);  // Close modal
          })
          .catch(error => {
              console.error('Error updating status:', error);
          });
    };




    const columns = [
      {
          title: 'Sr. No.',
          key: 'index',
          render: (_, __, index) => (currentPage - 1) * pageSize + index + 1,
        },
      {
          title: 'Class Name',
        dataIndex: 'classname',
        key: 'classname',
      },
      {
        title: 'Subject Name',
        dataIndex: 'subjectname',
        key: 'subjectname',
      },
      {
        title: 'Teacher Name',
        dataIndex: 'teachername',
        key: 'teachername',
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: 'Date',
        dataIndex: 'lec_date',
        key: 'lec_date',
      },
      {
        title: 'Time',
        dataIndex: 'lec_time',
        key: 'lec_time',
      },
      {
        title: 'Present Count',
        dataIndex: 'presentCount',
        key: 'presentCount',
      },
      {
        title: 'Status',
        dataIndex: 'lec_status',
        key: 'lec_status',
        render: (lec_status) => {
          let color = '';
          let label = '';
    
          switch (lec_status) {
            case 1:
              color = 'green';
              label = 'ACTIVE';
              break;
            case 2:
              color = 'orange';
              label = 'INACTIVE';
              break;
            default:
              label = 'Unknown';
          }
    
          return <span style={{ color }}>{label}</span>;
        },
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
            <MdEditDocument title='Edit' style={{fontSize:"20px",color:"orange",cursor:"pointer"}} onClick={() => handleEdit(record)} />
        ),
      },
    ];

    
// Filter data based on search input
  const filteredData = data.filter(item =>
    Object.values(item).some(value =>
      value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  );


  return (
    <>
        <HeaderPage/>

        <div className='container-fluid px-lg-5'>

                <div className='row px-3 px-lg-5 mt-2 d-flex align-items-center'>
                    <span className='componentTitle'> <AiOutlineAlignRight /> CREATED LECTURES</span>
                </div>

                <div className='row p-0 px-md-5 mb-5 m-0'>
                    <div className='row p-0 m-0  border shadow'>

                        <div className='row p-0 m-0 py-5'>
                              <div className='col-12 col-md-2 pt-2'></div>
                              <div className='col-12 col-md-3 pt-2'>
                                  <div className='row p-0 m-0'>
                                      <div className='col-4 col-md-4 p-0'>
                                            <label>From Date :</label>
                                      </div>
                                      <div className='col-8 col-md-8'>
                                          <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={fromDate}  onChange={(date) => setFromDate(date)} />
                                      </div>
                                  </div>
                              </div> 
                              <div className='col-12 col-md-3 pt-2'>
                                  <div className='row p-0 m-0'>
                                      <div className='col-4 col-md-4 p-0'>
                                            <label>To Date :</label>
                                      </div>
                                      <div className='col-8 col-md-8'>
                                      <DatePicker className='w-100' style={{height:"38px"}} format={dateFormatList}  value={toDate}  onChange={(date) => setToDate(date)} />
                                      </div>
                                  </div> 
                              </div> 
                              <div className='col-12 col-md-2 pt-4 pt-md-2 d-flex align-items-center justify-content-center'>
                                    <Button className='saveBtn' onClick={FetchTableData}>Show Data</Button>
                                    <Button className='cancelBtn' onClick={handleCancel}>Cancel</Button>
                              </div>
                              <div className='col-12 col-md-2'></div>
                        </div>

                        <div className='row m-0 pb-3'>            

                        {data.length > 0 ? (
                          <>
                                {/* ======>> table data <<====== */}

                                {isLoading ? (
                                    <div className="text-center">
                                        <img src={loaderImg} alt="Loading..." style={{ width: "100px" }} />
                                    </div>
                                ) : (
                                  
                                <div className='col-12 shadow py-5' style={{border:"1px solid #d3d3d3"}}>

                                <div className='row p-0 m-0 py-2' style={{borderBottom:"1px solid grey"}}>
                                    <div className='col-12 col-lg-6 text-start text-md-start'>
                                    <span style={{fontWeight:500}}>Show entries &nbsp; </span>
                                            <select onChange={(e) => setPageSize(Number(e.target.value))} value={pageSize}>
                                                <option value={10}>10</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </select>
                                    </div>
                                    <div className='col-12 col-lg-6 text-start text-md-end'>
                                    <span style={{fontWeight:500}}>Search &nbsp; </span>
                                        <Input
                                        placeholder="Search Here"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        style={{ width: 200 }}
                                        />
                                    </div>                   
                                </div>

                                <Table style={{overflow:"auto"}} columns={columns} 
                                    dataSource={filteredData}
                                    pagination={{
                                    current: currentPage,
                                    pageSize: pageSize,
                                    onChange: (page, pageSize) => {
                                      setCurrentPage(page);
                                      setPageSize(pageSize);
                                    }                            
                                  }}
                                 
                                    />
                                </div>
                                )}

                          </>
                          ) : (
                              <div className="text-center py-4">
                                    <h5>No Data Found</h5>
                              </div>
                          )}
                                  {/* Modal for editing lecture status */}
                                <Modal
                                    title="Edit Lecture Status"
                                    visible={isModalOpen}
                                    onOk={handleStatusUpdate}
                                    onCancel={() => setIsModalOpen(false)}
                                >
                                    <div className='d-flex pt-3'>
                                        <span className='fw-bold' style={{color:"orange"}}>INACTIVE</span> &nbsp;&nbsp;
                                        <Switch checked={toggleStatus} onChange={handleToggleChange} style={{width:"60px"}} /> &nbsp;&nbsp;
                                        <span className='fw-bold' style={{color:"green"}}>ACTIVE</span>
                                    </div>
                                    
                                </Modal>

                        </div>

                    </div>
                </div>

               

        </div>

        <FooterPage/>
    </>
  )
}

export default CreatedLectures